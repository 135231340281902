// types
import type { FieldsGroup } from '../nord/types';
import type { FieldProps } from '../field';

// utils
import { FieldsetsMap } from './fieldsets-map';

const FIELDSET_START = 'fieldset_start';
const FIELDSET_END = 'fieldset_end';

const withIndex = (field: FieldProps, index: number) => ({ ...field, index });

export function buildFieldsetsMap(fields: FieldProps[]): FieldsGroup[] {
  const fieldsetsMap = new FieldsetsMap();

  for (const field of fields.map(withIndex)) {
    if (field.type === FIELDSET_START) {
      fieldsetsMap.beginFieldset(field);
      continue;
    }

    if (field.type === FIELDSET_END) {
      fieldsetsMap.finishFieldset();
      continue;
    }

    fieldsetsMap.addField(field);
  }

  fieldsetsMap.finish();

  return fieldsetsMap.fieldsetsGroup;
}
