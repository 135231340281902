import React from 'react';
import styles from './voucher-control.module.scss';
import { Icon } from '@geberit/gdds';
import { VoucherFormProps } from 'components/voucher-form/voucher-display';

type VoucherControlProps = Pick<VoucherFormProps, 'displayOnly' | 'removeVoucher'> & {
  appliedVoucher: string;
};

export const VoucherControl: React.FC<VoucherControlProps> = (props) => (
  <div className={styles.voucherControl}>
    <p>{props.appliedVoucher}</p>
    {!props.displayOnly && (
      <button onClick={props.removeVoucher}>
        <Icon symbol="trash" width="20px" />
      </button>
    )}
  </div>
);
