// components
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { useGdds } from 'utils/hooks/use-gdds';

export type InlineTextField = {
  value: string;
};

interface InlineTextProps {
  field: InlineTextField;
}

function InlineText({ field: { value } }: Readonly<InlineTextProps>) {
  const isGdds = useGdds();

  return <InnerHtml as="div" className="inline-text" content={value} isGdds={isGdds} />;
}

export default InlineText;
