export function mapBrand(brandName) {
  let brand;

  switch (brandName) {
    case 'porsgrund':
      brand = 'porsgrund';
      break;
    case 'ifo':
      brand = 'ifo';
      break;
    case 'ido':
      brand = 'ido';
      break;
    default:
      brand = 'geberit';
  }

  return brand;
}
