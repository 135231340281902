// utils
import { decodingContent } from 'utils/decodingContent';

const isInFilter = (values, filters) => {
  return values.some((value) => filters.some((filter) => decodingContent(filter) === value));
};

// input: filter1 and filter2 values and items to filter --> output: filtered items
export const returnFilteredItems = async (filter1Value, filter2Value, results) => {
  const filteredResults = [];

  // apply filter1
  for (let result of results) {
    if (result.meta.filter1.includes(filter1Value)) {
      filteredResults.push(result);
    }
  }

  let doubleFilteredResults = [];

  // apply filter2
  if (filter2Value.length > 0) {
    for (let result of filteredResults) {
      if (isInFilter(filter2Value, result.meta.filter2)) {
        doubleFilteredResults.push(result);
      }
    }
  } else {
    doubleFilteredResults = filteredResults;
  }
  return doubleFilteredResults;
};

export const returnMediaFilteredItems = (filter4Value, items) => {
  const filteredItems = [];
  if (filter4Value.length) {
    for (let item of items) {
      for (let filter of item.meta.filter4) {
        const found = filteredItems.some((element) => element.id === item.id);
        if (!found) {
          if (filter4Value.includes(filter)) {
            filteredItems.push(item);
          }
        }
      }
    }
  }
  return filteredItems;
};

export const sortItemsByDate = async (items) => {
  const sortedItems = [];
  items.forEach((categoryItems) => {
    const sortedCategoryItems = categoryItems.sort((a, b) => {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      return 0;
    });
    sortedItems.push(sortedCategoryItems);
  });
  return sortedItems;
};
