import { useCallback, useRef } from 'react';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useFormikContext } from 'formik';
import key from 'weak-key';

interface CaptchaProps {
  fieldName: string;
}

export default function Captcha({ fieldName }: Readonly<CaptchaProps>) {
  const rerendered = useRef(false);
  const { setFieldValue } = useFormikContext<Record<string, string>>();

  const verifyCallback = useCallback(
    (response) => {
      if (rerendered.current === false) {
        setFieldValue(fieldName, response || '');
        rerendered.current = true;
      }
    },
    [fieldName, setFieldValue],
  );

  return <GoogleReCaptcha key={key(new Date())} onVerify={verifyCallback} />;
}
