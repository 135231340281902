import axios from 'axios';
import { isArray, isObject } from 'lodash';

// types
import { Dispatch } from 'redux';
import type { ModelsResponse } from 'services/models';

// Utils
import getEndpoint from 'utils/endpoints';
import currentLanguageSelector from 'utils/selectors/languageSelectors';
import { isEmpty } from 'utils/is-empty';

/**
 * @returns {Object} action
 */
function setPreConDataPending() {
  return {
    type: 'PRE_CON_DATA_PENDING' as const,
  };
}

/**
 * @param {Array} products
 *
 * @returns {Object} action
 */
function setPreConDataFulFilled({ data: { products } }: ModelsResponse, language: string) {
  return {
    type: 'PRE_CON_DATA_FULFILLED' as const,
    products,
    language,
  };
}

/**
 * @param {Array} products
 *
 * @returns {Object} error
 */
function setPreConDataError(error: RequestError, language: string) {
  return {
    type: 'PRE_CON_DATA_REJECTED' as const,
    error,
    language,
  };
}

/**
 * Fetch dependency dropdown values
 */
export function fetchPreConData() {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const language = currentLanguageSelector(state);
    const endpoint = `${getEndpoint('models', state)}/?language=${language}`;
    dispatch(setPreConDataPending());

    try {
      const { data } = await axios.get(endpoint);
      dispatch(setPreConDataFulFilled(data, language));
    } catch (e) {
      dispatch(setPreConDataError(e.response, language));
    }
  };
}

async function uploadFile({
  data,
  filesTotal,
  index,
  setUploadProgress,
  blobStorage,
  getEndpoint,
}) {
  const response = await axios({
    method: 'post',
    url: `${getEndpoint('form')}/uploadfile/`,
    data,
    onUploadProgress: ({ loaded, total }) => {
      const currentPercentage = Math.round((loaded / total) * 100);
      const maxPercentagePerFile = 100 / filesTotal;
      const curStartPercentage = Math.round(maxPercentagePerFile * index);

      const curPercentagePerFile = Math.round((currentPercentage / 100) * maxPercentagePerFile);
      if (currentPercentage !== 0) {
        setUploadProgress(curStartPercentage + curPercentagePerFile);
      }
    },
    headers: {
      uploadToBlobStorage: blobStorage,
    },
  });

  if (isEmpty(response) || isEmpty(response.data) || response?.status !== 200) {
    throw new Error('UPLOAD_ERROR');
  }

  // save error message
  if (response.data.state === 'FILETYPE' || response.data.state === 'FILESIZE') {
    throw new Error(response.data.state);
  }

  return response;
}

/**
 * Upload inputfield files
 *
 * @param files
 * @param uploadToBlobStorage
 * @returns {Promise<*>}
 */
async function uploadInputFiles(files, blobStorage, setUploadProgress, getEndpoint) {
  let uid;
  let blobStorageData: {
    container: string;
    fileName: string;
  }[] = [];

  // only one fileupload button is suggested for a form (discussed with Philipp)
  const firstKey = Object.keys(files)[0];
  const filesTotal = files[firstKey].length;
  let index = 0;

  setUploadProgress(0.1);

  for (const file of files[firstKey]) {
    const data = new FormData();

    data.append('file', file);

    if (!isEmpty(uid)) {
      data.append('uuid', uid);
    }

    try {
      // need response uid for next request
      const response = await uploadFile({
        data,
        filesTotal,
        index,
        setUploadProgress,
        blobStorage,
        getEndpoint,
      });

      if (blobStorage) {
        blobStorageData = [
          ...blobStorageData,
          {
            container: response.data.container,
            fileName: response.data.filepath,
          },
        ];
      } else {
        if (!response.data.uuid) {
          return 'UPLOAD_ERROR';
        }
        uid = response.data.uuid;
      }

      index += 1;
    } catch (error) {
      return 'UPLOAD_ERROR';
    }
  }

  if (blobStorage) {
    return { uploads: blobStorageData };
  }

  return {
    [firstKey]: uid,
  };
}

export function getResponse({
  pageId,
  formId,
  values,
  files,
  deleteForRequest,
  uploadToBlobStorage,
  setUploadProgress,
  getEndpoint,
}) {
  if (pageId === '' || formId === '') {
    return Promise.reject(new Error('invalid form'));
  }

  let newValues = values;

  return (async () => {
    // get file ids
    if (!isEmpty(files)) {
      const response = await uploadInputFiles(
        files,
        uploadToBlobStorage,
        setUploadProgress,
        getEndpoint,
      );
      // return if there is an error with the filesize or type
      if (typeof response === 'string') {
        throw new Error(response);
      } else {
        newValues = {
          ...newValues,
          ...response,
        };
      }
    }

    const requestValues = { ...newValues };
    Object.keys(requestValues).forEach((key) => {
      if (deleteForRequest.some((v) => v === key)) {
        delete requestValues[key];
      }
    });

    const encodedRequestValues = encodeObject(requestValues);

    const res = await axios.post(`${getEndpoint('form')}/submit/`, {
      formId,
      pageId,
      ...encodedRequestValues,
    });

    if (typeof res.data === 'string') {
      const data = JSON.parse(res.data);
      return data;
    }
    return res.data;
  })();
}

function encodeObject(obj: object) {
  return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, encode(value)]));
}

function encode(value: any) {
  if (isArray(value)) {
    return encodeArray(value);
  } else if (isObject(value)) {
    return encodeObject(value);
  } else if (typeof value === 'string') {
    return encodeRFC3986URIComponent(value);
  }
  return value;
}

function encodeArray(value: any[]) {
  return value.map(encode);
}

function encodeRFC3986URIComponent(inputString: string) {
  return encodeURIComponent(inputString).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
  );
}

export type FormAction =
  | ReturnType<typeof setPreConDataPending>
  | ReturnType<typeof setPreConDataFulFilled>
  | ReturnType<typeof setPreConDataError>;
