import { MY_DATA } from '../constants';
import { getInitialValues } from 'components/Checkout/Components/AddressForm/utils';
import { DeliveryAddress, InvoiceAddress } from 'components/Checkout/action.types';

export const createFormObject = (values: ReturnType<typeof getInitialValues>) => {
  const mapEmpties = <T extends string | undefined>(value: T): T => {
    if (value === '') return undefined as T;
    return value?.trim() as T;
  };

  const invoiceAddress = {
    city: values.city.trim(),
    company: mapEmpties(values.company),
    country: values.country,
    email: values.email.trim(),
    firstName: values.firstName.trim(),
    lastName: values.lastName.trim(),
    mobile: mapEmpties(values.mobile),
    phone: mapEmpties(values.phone),
    salutation: mapEmpties(values.salutation),
    street: mapEmpties(values.street),
    street2: mapEmpties(values.street2),
    zip: values.zip.trim(),
  } satisfies Partial<InvoiceAddress>;

  let deliveryAddress = invoiceAddress;

  if (values.deliveryCheckbox) {
    deliveryAddress = {
      city: values.deliveryCity.trim(),
      company: mapEmpties(values.deliveryCompany),
      country: values.deliveryCountry,
      email: values.email.trim(),
      firstName: values.deliveryFirstName.trim(),
      lastName: values.deliveryLastName.trim(),
      mobile: mapEmpties(values.mobile),
      phone: mapEmpties(values.phone),
      salutation: mapEmpties(values.deliverySalutation),
      street: mapEmpties(values.deliveryStreetAndHouse),
      street2: mapEmpties(values.deliveryStreet2),
      zip: values.deliveryZipCode.trim(),
    } satisfies Partial<DeliveryAddress>;
  }

  return {
    invoiceAddress,
    deliveryAddress,
  };
};

export const transformPrice = (price) => Number(price).toFixed(2);

export const mapSapAndCiamCodesToSalutations = (code) => {
  switch (code) {
    case '0001':
      return 'web20_checkout_address_salutation_2';
    case '0002':
      return 'web20_checkout_address_salutation_1';
    case '9008':
      return 'web20_checkout_address_salutation_3';
    case 'Mr.':
      return 'web20_checkout_address_salutation_1';
    case 'Ms.':
      return 'web20_checkout_address_salutation_2';
    case 'Divers':
      return 'web20_checkout_address_salutation_3';
    default:
      return '';
  }
};

export const mapSapCiamSalutationsToSapCodes = (code) => {
  switch (code) {
    case 'Mr.':
      return '0002';
    case 'Ms.':
      return '0001';
    case 'Divers':
      return '9008';
    case '0001':
      return '0001';
    case '0002':
      return '0002';
    case '9008':
      return '9008';
    default:
      return '';
  }
};

export const hasSessionData = (userType) => {
  const getStorageData = window.sessionStorage.getItem(MY_DATA);

  if (getStorageData) {
    const parsedData = JSON.parse(getStorageData);
    return !!parsedData[userType];
  }

  return false;
};

export const userType = (check) => (check ? 'user' : 'guest');
