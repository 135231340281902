// components
import { Message } from '../Message/Message';
import { EditWrapper } from '../ContentCreator/EditWrapper';

// utils
import { useIsPreview } from 'utils/hooks/useIsPreview';

interface ComponentNotFoundProps {
  type: string;
  previewId?: string;
}

export function ComponentNotFound({ type, previewId }: Readonly<ComponentNotFoundProps>) {
  const isPreview = useIsPreview();
  if (!isPreview) return null;

  return (
    <div className="grid-container">
      <EditWrapper previewId={previewId}>
        <Message
          closable={false}
          type="warning"
          content={`Section "${type}" is deprecated and can be deleted.`}
        />
      </EditWrapper>
    </div>
  );
}
