import React, { useState, useRef, useEffect } from 'react';
import { format } from 'date-fns';
import { InnerHtml, Icon, Button } from '@geberit/gdds';

// styles
import styles from './dlc-media-tile.module.scss';

// types
import type { DownloadcenterMediaTileProps } from '../types';

// components
import { Translation } from 'components/Translation/Translation';

// utils
import { humanizeFileSize } from 'utils/fileSize';
import { useTracking } from 'utils/hooks/useTracking';
import { clickDownloadsLink } from 'features/download-center/tracking';
import mapItemFileType from './utils/mapItemFileType';
import { decodingContent } from 'utils/decodingContent';
import { useIsMobile } from 'components/App/SizeProvider';

export default function DownloadcenterMediaTile(props: Readonly<DownloadcenterMediaTileProps>) {
  const {
    item,
    setDownloadCounter,
    downloads,
  } = props;
  const isMobile = useIsMobile();
  const [downloaded, setDownloaded] = useState(false);
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const track = useTracking();

  const toggleDownload = () => {
    if (downloaded) {
      setDownloaded(false);
      setDownloadCounter(-1);
    } else {
      setDownloaded(true);
      setDownloadCounter(1);
    }
  };

  // show document
  const handleDownloadsLinkClick = (name) => {
    track.trackEvent(clickDownloadsLink(name, item.title));
  };

  // set downloaded and ordered on load to set the corresponding button style
  useEffect(() => {
    if (downloads) {
      let isDownloaded = false;
      downloads.forEach((download) => {
        if (item.id === download.id) {
          isDownloaded = true;
        }
      });
      setDownloaded(isDownloaded);
    }
  }, [downloads]);

  return (
    <div className={styles.mediaTileWrapper}>
      {/* media download active badge */}
      {!isHover && downloaded && !isMobile && (
        <div className={styles.mediaDownloadIndicator}>
          <Button
            onClick={() => {
              toggleDownload();
            }}
            stylingType="icon"
            selected={downloaded}
            rounded
            symbol="Download"
            isIcon
          />
        </div>
      )}
      {/* Text Image */}
      <div className={styles.imageTextWrapper} ref={hoverRef}>
        <img src={item.meta?.picture} className={styles.mediaTileImage} alt="geberitImage" />
        <div className={styles.mediaTileTextArea}>
          <span className={styles.mediaTileDate}>{format(new Date(item.date), 'dd.MM.yyyy')}</span>
          <InnerHtml as="span" content={decodingContent(item.title, true)} />
          <div className={styles.mediaTileFileInfo}>
            <span className={styles.mediaTileFileIcon}>
              {item.meta?.fileExtension && item.meta?.fileExtension?.[0] ? (
                <Icon
                  symbol={
                    mapItemFileType[item.meta?.fileExtension?.[0]]
                      ? mapItemFileType[item.meta?.fileExtension?.[0]]
                      : 'File'
                  }
                />
              ) : (
                <Icon symbol="File" />
              )}
            </span>
            {item.meta?.fileExtension &&
              item.meta?.fileExtension?.[0] &&
              `${item.meta?.fileExtension?.[0].toUpperCase()} `}

            {item.size > 0 && `(${humanizeFileSize(item.size * 1000, true).toUpperCase()})`}
          </div>
        </div>
      </div>
      {/* Buttons shows on hover */}
      <div className={styles.mediaTileButtons}>
        <a
          href={item.meta?.fileUrl?.[0]}
          target="_blank"
          className={styles.mediaIconHover}
          rel="noreferrer"
        >
          <Button
            stylingType="icon"
            rounded
            symbol="Catalogue"
            isIcon
            onClick={() => handleDownloadsLinkClick('Open')}
          />
          <Translation id="group_downloadcenter_open" />
        </a>
        <div className={styles.buttonDownload}>
          <Button
            onClick={() => {
              toggleDownload();
            }}
            stylingType="icon"
            selected={downloaded}
            rounded
            symbol="Download"
            isIcon
          />
          <Translation id="group_downloadcenter_download" />
        </div>
      </div>
    </div>
  );
}

function useHover(elementRef) {
  const [value, setValue] = useState(false);
  const handleMouseEnter = () => setValue(true);
  const handleMouseLeave = () => setValue(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const node = elementRef?.current;

    if (node) {
      node.addEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleMouseLeave);
      return () => {
        node.removeEventListener('mouseenter', handleMouseEnter);
        node.removeEventListener('mouseleave', handleMouseLeave);
      };
    }
  }, [elementRef]);
  return value;
}
