export async function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    return copyToClipboardLegacyBrowsers(text);
  } else {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (e) {
      return copyToClipboardLegacyBrowsers(text);
    }
  }
}

function copyToClipboardLegacyBrowsers(text: string) {
  const textarea = document.createElement('textarea');
  document.querySelector('body')?.appendChild(textarea);
  textarea.value = text;
  textarea.select();
  const status = document.execCommand?.('copy');
  textarea.remove();

  return status;
}
