import React from 'react';

// types
import { AutocompletePrediction } from 'components/ContentElements/Locator/locator.types';
import { IHighlightedprediction } from 'components/ContentElementsGdds/locator/locator.types';

export function highlightPredictions(
  predictions: AutocompletePrediction[],
  hightLightOnlyMainText?: boolean,
): IHighlightedprediction[] {
  return predictions.map((prediction) => {
    const { main_text, secondary_text, main_text_matched_substrings } =
      prediction.structured_formatting;

    const machtedSubstrings = hightLightOnlyMainText
      ? [{ offset: 0, length: main_text.length }]
      : main_text_matched_substrings;

    const hightlighted_main_text = highlightSubStrings(main_text, machtedSubstrings || []);

    return {
      mainText: main_text,
      secondaryText: secondary_text,
      hightlightedMainText: hightlighted_main_text,
      prediction,
    };
  });
}

const highlightSubStrings = (
  text: string,
  matched_substrings: AutocompletePrediction['matched_substrings'],
) => {
  const results: (string | JSX.Element)[][] = [];

  for (let i = 0; i < matched_substrings.length; i++) {
    const startOfCurrent = i === 0 ? 0 : matched_substrings[i].offset;
    const startOfNext = matched_substrings[i + 1]?.offset;

    results.push(highlightText(text, matched_substrings[i], startOfCurrent, startOfNext));
  }

  return results.map((text) => <>{text}</>);
};

const highlightText = (
  text: string,
  matched_substring: AutocompletePrediction['matched_substrings'][number],
  start: number,
  end: number,
) => {
  const highlightTextStart = matched_substring.offset;
  const highlightTextEnd = highlightTextStart + matched_substring.length;
  const beforeText = text.slice(start, highlightTextStart);
  const highlightedText = text.slice(highlightTextStart, highlightTextEnd);
  const afterText = text.slice(highlightTextEnd, end || text.length);

  return [
    beforeText,
    <span className="highlight" key={`${start}-${end}`}>
      {highlightedText}
    </span>,
    afterText,
  ];
};
