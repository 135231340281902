import { useContext, useEffect } from 'react';

// types
import { IEntryWithKey } from '../providers/filter-provider';
import { IWithDistance } from '../utils/pins-manager';

// utils
import { RefsContext } from '../providers';
import { useIsDesktop } from 'components/App/SizeProvider';

export const PORTAL_GAP = 44; // according to design

function isHTMLElement(element: HTMLElement | null): element is HTMLElement {
  return !!element;
}

export function useScrollHandler(activePin: IWithDistance<IEntryWithKey> | null) {
  const isDesktop = useIsDesktop({ gdds: true });
  const { mapRef, portalRef, transparentLayerRef } = useContext(RefsContext);

  useEffect(() => {
    const scrollHandler = () => {
      if (
        !isHTMLElement(mapRef.current) ||
        !isHTMLElement(portalRef.current) ||
        !isHTMLElement(transparentLayerRef.current)
      ) {
        return;
      }

      const { height: mapHeight } = mapRef.current.getBoundingClientRect();
      const shouldStick = window.scrollY < mapHeight - PORTAL_GAP;

      if (!activePin) {
        if (shouldStick) {
          portalRef.current.style.maxHeight = `${Math.ceil(mapHeight - window.scrollY)}px`;
          transparentLayerRef.current.style.backgroundColor = `rgba(0,0,0, ${
            Math.round(100 * 0.2 * (window.scrollY / (mapHeight - PORTAL_GAP))) / 100
          })`;
          transparentLayerRef.current.classList.remove('active');
          portalRef.current.style.zIndex = '2';
        } else {
          portalRef.current.style.maxHeight = `${PORTAL_GAP}px`;
          transparentLayerRef.current.style.backgroundColor = `rgba(0,0,0, 0.2)`;
          transparentLayerRef.current.classList.add('active');
          portalRef.current.style.zIndex = '5';
        }
      } else {
        transparentLayerRef.current.style.backgroundColor = `rgba(0,0,0, 0)`;
        transparentLayerRef.current.classList.remove('active');
      }
    };

    if (!isDesktop) {
      scrollHandler();
      window.addEventListener('scroll', scrollHandler);
    } else {
      if (isHTMLElement(transparentLayerRef.current)) {
        transparentLayerRef.current.style.backgroundColor = '';
      }
      if (isHTMLElement(portalRef.current)) {
        portalRef.current.style.height = '';
        portalRef.current.style.zIndex = '';
      }
    }

    return () => {
      if (!isDesktop) {
        window.removeEventListener('scroll', scrollHandler);
      }
    };
  }, [isDesktop, activePin]);
}
