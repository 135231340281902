import { useRouter } from 'next/navigation';
import { Tiles, ExpressiveTile, Row, Col, Typo, Divider } from '@geberit/gdds';

// styles
import styles from './press-tiles.module.scss';

// types
import type { Material } from '../types/mynewsdesk.types';
import type { PressTilesProps, RelatedMaterialsDisplayMode } from '../types/press-portal.types';

// components
import { InnerHtml } from 'components/InnerHtml/inner-html';
import Title from '../../title/title';

// utils
import { formatDate, getMaterialUniqueIdentifier } from '../utils';
import { useIsMobile, useIsTablet } from 'components/App/SizeProvider';
import { classNameBuilder } from 'utils/classNameBuilder';
import { useLocale } from 'utils/use-locale';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import { useGdds } from 'utils/hooks/use-gdds';

export default function RelatedPressTiles({ language, materials }: Readonly<PressTilesProps>) {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isGdds = useGdds();
  const router = useRouter();
  const locale = useLocale(language);
  const translate = useTranslationFunction();
  const pressRelatedNews = translate('press_related_news');

  const getImageUrl = (material: Material, originSize?: boolean) => {
    if (!material) {
      return undefined;
    }

    if (originSize) return material.image_medium;
    if (isMobile) return material.image_small;
    if (isTablet) return material.image_medium;

    return material.image_medium;
  };

  if (isEmpty(materials)) return null;

  const relatedMaterialsDisplayMode: RelatedMaterialsDisplayMode =
    materials.length > 2 ? 'three_per_row' : 'two_per_row';

  return (
    <div className={styles.materialsWrapper}>
      <Divider />
      <Title title={pressRelatedNews} />
      <Row
        className={classNameBuilder(
          styles.relatedNews,
          relatedMaterialsDisplayMode === 'two_per_row' && styles.twoTilesPerRow,
        )}
      >
        {relatedMaterialsDisplayMode === 'two_per_row' && <Col size={[0, 0, 2]}></Col>}
        <Col size={[4, 8, relatedMaterialsDisplayMode === 'two_per_row' ? 8 : 12]}>
          <Tiles type="expressive">
            {materials.map((material) => {
              const pathname = `?page=detailpage&title=${getMaterialUniqueIdentifier(material)}`;
              const target = new URL(pathname, window.location.href).toString();
              const link =
                typeof window !== 'undefined'
                  ? {
                      text: translate('press_learn_more'),
                      target,
                      hasArrow: false,
                      onClick: (e: Event) => {
                        e.preventDefault();
                        router.push(pathname, undefined, { shallow: true });
                      },
                    }
                  : undefined;
              const imageStyles = {
                size: 'cover' as const,
                repeat: false,
              };
              const datetimeText = formatDate(material, locale);

              const commonProps = { link, imageStyles };

              return (
                <ExpressiveTile
                  key={material.id}
                  type={'tleb1'}
                  size="s"
                  renderHeadline={() => (
                    <Typo
                      as="span"
                      className={classNameBuilder(styles.publishedAt, styles.tleb1)}
                      variant="p2"
                    >
                      {datetimeText}
                    </Typo>
                  )}
                  customParagraph={
                    <InnerHtml
                      className={classNameBuilder(styles.copyText, styles.tleb1)}
                      as="h4"
                      content={material.header}
                      isGdds
                    />
                  }
                  image={getImageUrl(material)}
                  {...commonProps}
                />
              );
            })}
          </Tiles>
        </Col>
        {relatedMaterialsDisplayMode === 'two_per_row' && <Col size={[0, 0, 2]}></Col>}
      </Row>
    </div>
  );
}
