import { useContext } from 'react';

// types
import { DealerType } from '../locator.types';

// utils
import { useSearchParamsState } from 'utils/hooks/use-search-params-state';
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { FilterContext } from '../map';
import { decodingContent } from 'utils/decodingContent';

type IUsePrimaryFilter = [
  {
    label: { all: string; showroom: string; plumber: string };
    active: PrimaryFilter[];
    selected: PrimaryFilter | undefined;
  },
  (select: PrimaryFilter | undefined) => void,
];

export function usePrimaryFilter(): IUsePrimaryFilter {
  const [state, setSearchParamsState] = useSearchParamsState(['cat'], ['all']);
  const { activeCategories } = useContext(FilterContext);
  const locatorTranslations = useLocatorConfiguration();

  return [
    {
      label: {
        all: decodingContent(locatorTranslations?.filter?.filter1Text || ''),
        showroom: decodingContent(locatorTranslations?.filter?.filter2Text || ''),
        plumber: decodingContent(locatorTranslations?.filter?.filter3Text || ''),
      },
      active: activeCategories,
      selected: state['cat'] as PrimaryFilter,
    },
    (newValue) => {
      setSearchParamsState({ cat: newValue });
    },
  ];
}

export type PrimaryFilter = Lowercase<DealerType | 'all'>;
