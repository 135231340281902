import styled from 'styled-components';

export const NoteWrapper = styled.div`
  grid-area: note-wrapper;
  margin-top: 4rem;
  background-color: ${({ theme }) => theme.colors.grey03};
  padding: 1.5rem 3rem 2.75rem 1.5rem;

  @media print {
    margin-top: 0;
  }

  @media ${({ theme }) => theme.device.large} {
    margin-top: 5rem;
  }

  .note {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    margin-bottom: 0.25rem;
  }

  > p {
    color: ${({ theme }) => theme.colors.grey14};
    padding-left: 1.5rem;
  }
`;
