// utils
import { isEmpty } from 'utils/is-empty';
import { PRE_CON_INIT_VALUE_MAPPING, PRE_CON_VARIABLE_NAMES } from './mappings';

/**
 * Create Initial Values for the form
 *
 * @param {Object} fields
 */
export function getInitValues(fields) {
  return fields
    .filter(
      (field) =>
        field.fieldType !== 'form_field_button' && field.fieldType !== 'form_field_inlinetext',
    )
    .reduce((field, composed) => {
      const { type, value } = composed;
      let initValue;

      const fieldName = type === 'form_captcha' ? composed.fieldName : composed.name;

      switch (type) {
        case 'checkbox':
        case 'file':
          initValue = [];
          break;
        case 'hidden':
          initValue = value;
          break;
        case 'dropdown': {
          const hasDefaultValue =
            !isEmpty(composed.options) && !isEmpty(composed.defaultValue) && composed.defaultValue;
          const defaultValue = hasDefaultValue
            ? composed.options.find((option) => option.title === hasDefaultValue)
            : '';
          initValue =
            defaultValue.value ||
            (!isEmpty(composed.options) && isEmpty(defaultValue) && composed.options[0].value);
          break;
        }
        case 'dropdown_models': {
          let preConValues = {};

          PRE_CON_VARIABLE_NAMES.forEach((key, i) => {
            const name = composed[key] || PRE_CON_INIT_VALUE_MAPPING[i];

            preConValues = {
              ...preConValues,
              [name]: '',
            };
          });

          return {
            ...field,
            ...preConValues,
          };
        }
        default:
          initValue = '';
      }

      return {
        ...field,
        [fieldName]: initValue,
      };
    }, {});
}
