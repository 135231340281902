import slugify from 'slugify';
import key from 'weak-key';

export function getSectionId(
  anchor: string,
  tocData?: readonly { id: string }[],
  contentIndex?: number,
) {
  const id: string = anchor
    ? `section-${generateSectionIdSlug(anchor)}`
    : (tocData?.[contentIndex ?? 0]?.id ?? `section-index-${contentIndex}`);

  return id;
}

export const generateSectionIdSlug = (phrase: string, prefix?: string) => {
  if (!phrase || phrase.length === 0) return key({ phrase });

  const slug = slugify(`${phrase}`, {
    remove: /[*+~.,;#()'"!:`/–@?°]/g,
    lower: true,
  });

  return prefix ? `${prefix}-${slug}` : slug;
};
