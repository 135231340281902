import { Dropdown } from '@geberit/gdds';

// types
import type { FilterNordProps } from '../../types';
import type { SelectOption } from '@geberit/gdds/dist/esm/form/Dropdown/types/Dropdown.types';

// utils
import { useTranslationByKey } from 'utils/hooks/use-translations';

export default function FilterNord(props: Readonly<FilterNordProps>) {
  const {
    activeItems,
    filterKey = 'invalid or empty string',
    options,
    handleFilterChange,
  } = props;
  const filterTranslation = useTranslationByKey(`web20_downloadcenter_${filterKey}`);

  return (
    <Dropdown
      name={filterKey}
      placeholder={filterTranslation}
      items={options.map((p) => ({
        ...p,
        id: p.value,
        text: p.value,
      }))}
      label={filterTranslation}
      onChange={(option: SelectOption[]) => handleFilterChange(filterKey, option)}
      outlined
      values={{ [filterKey]: activeItems }}
      multiple
      forceCustom
    />
  );
}
