import { globalsSearchSelector } from './globalsSelectors';

/**
 * Reads query string from redux state
 * @param {*} state
 */
export const selectParamsFromState = (state: AppState) => state.search.params;

/**
 * Search query selector
 */
export const selectSearchQuery = (state: AppState) => {
  const params = selectParamsFromState(state);

  if (!params) return undefined;

  return params.q;
};

/**
 * Search soft content response
 */
export const selectSoftContent = (state: AppState) => state.search.softContent;

/**
 * Search soft content amount
 */
export const selectSearchContentResultsTotal = (state: AppState) => {
  const softcontent = selectSoftContent(state);

  if (!softcontent) return 0;

  return softcontent.totalResults;
};

/**
 * Search products response
 */
export const selectProducts = (state: AppState) => state.search.products;

/**
 * Search products amount
 */
export const selectSearchProducttResultsTotal = (state: AppState) => {
  const products = selectProducts(state);

  if (!products) return 0;

  return products.total;
};

/**
 * Search fetching indicator
 */
export const searchIsFetchingSelector = (state: AppState) => state.search.isFetching;

/**
 * Search no results selector
 */
export const searchNoResultsSelector = (state: AppState) => {
  const content = selectSearchContentResultsTotal(state);
  const articles = selectSearchProducttResultsTotal(state);
  const search = globalsSearchSelector(state);

  return (
    (!content && !articles) ||
    (search?.articlesDisabled && !content) ||
    (search?.contentDisabled && !articles)
  );
};
