'use client';

// types
import type { DefaultItemProps } from './default.types';

// components
import Footer from 'components/Footer/Footer';
import { ContentArea } from 'components/ContentArea/ContentArea';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import RelatedLinks from 'components/RelatedLinks/RelatedLinks';
import SocialShare from 'components/SocialShare/SocialShare';
import TableOfContents from 'components/TableOfContents/TableOfContents';
import { IntroText } from 'components/ContentElementsGdds/IntroText/intro-text';
import { HeroImageGdds } from 'components/ContentElementsGdds/hero-image/hero-image-gdds';

// utils
import { TOC_DATA_CONTENTPAGE } from 'components/TableOfContents/tocDataAdapter';
import { isEmpty } from 'utils/is-empty';
import { getHeroImageItems } from './utils';
import { hasContentAreas, useContent } from 'components/Page/page-hooks';

export function Default() {
  const content = useContent<{}, { heroTileItems: DefaultItemProps[] }>();

  if (!hasContentAreas(content)) {
    return null;
  }

  const {
    breadcrumbs,
    metaData,
    relatedLinks,
    page: { subHeadline, headline, heroTileItems, socialSharing, showAnchor, intro },
    contentAreas,
  } = content;

  const hasLocatorSection =
    !isEmpty(contentAreas?.content) && contentAreas.content[0].type === 'locator';

  const heroImageItems = getHeroImageItems({ items: heroTileItems });

  return (
    <>
      <main>
        <HeroImageGdds items={heroImageItems} />
        <Breadcrumb items={breadcrumbs} />
        {(headline || subHeadline) && (
          <IntroText
            title={headline}
            intro={intro}
            subtitle={subHeadline}
            text={''}
            border="disable-border"
            emphasize='title'
          />
        )}

        {showAnchor && !hasLocatorSection && (
          <TableOfContents
            type={TOC_DATA_CONTENTPAGE}
            headline={headline}
            content={contentAreas.content}
          />
        )}
        <ContentArea content={contentAreas.content} contentType="content" />
        {socialSharing === 'true' ? (
          <section className="grid-container">
            <SocialShare contentType="page" metaData={metaData} useInContentPage />
          </section>
        ) : null}
        <RelatedLinks
          title={relatedLinks?.title}
          anchor={relatedLinks?.anchor}
          categories={relatedLinks?.categories}
        />
      </main>
      <Footer />
    </>
  );
}
