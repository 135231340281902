import { useSelector } from 'react-redux';

// styles
import styles from './summaryContainer.module.scss';

// types
import { CheckoutState } from '../../reducer.types';

// components
import { Translation } from 'components/Translation/Translation';
import { Button, BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES } from 'components/Button/Button';
import { AddressBox } from '../../Components/AddressBox/AddressBox';
import OrderDetailsBox from '../../Components/OrderDetailsBox/OrderDetailsBox';

// utils
import { mapSapAndCiamCodesToSalutations } from '../../util/helperFunctions';
import { webshopLandingpageUrlSelector } from 'utils/selectors/globalsSelectors';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';
import OrderContents from 'components/Checkout/Containers/OrderContents/OrderContents';
import { useVoucher } from 'utils/hooks/useVoucher';
import { classNameBuilder } from 'utils/classNameBuilder';

interface SummaryDataProps {
  orderId: string;
  orderData: CheckoutState['orderData'];
}

export function SummaryData({ orderId, orderData }: Readonly<SummaryDataProps>) {
  const translate = useTranslationFunction();
  const webshopHomeUrl = useSelector(webshopLandingpageUrlSelector);

  const voucher = useVoucher(orderData.invoiceAddress, orderData.deliveryAddress);

  const salutationTranslationKeyInvoiceAddress =
    orderData && !isEmpty(orderData.invoiceAddress) && orderData.invoiceAddress.salutation
      ? mapSapAndCiamCodesToSalutations(orderData.invoiceAddress.salutation)
      : '';
  const salutationTranslationKeyDeliveryAddress =
    orderData && !isEmpty(orderData.deliveryAddress) && orderData.deliveryAddress.salutation
      ? mapSapAndCiamCodesToSalutations(orderData.deliveryAddress.salutation)
      : '';

  return (
    <>
      <div className="grid-x">
        <div className="cell small-12 medium-4 large-4">
          <OrderDetailsBox
            orderData={orderData.orderDetails}
            orderId={orderId}
            title={translate('web20_checkout_summary_order_heading')}
          />
        </div>
        <div className="cell small-12 medium-4 large-4">
          <AddressBox
            country={translate(`countries_${orderData.invoiceAddress.country.toLowerCase()}`)}
            salutation={translate(salutationTranslationKeyInvoiceAddress)}
            address={{
              ...orderData.invoiceAddress,
              sapCustomerNumber: orderData.sapCustomerNumber,
            }}
            title={translate('web20_checkout_address_invoice_form_heading')}
          />
        </div>
        <div className="cell small-12 medium-4 large-4">
          <AddressBox
            country={translate(`countries_${orderData.deliveryAddress.country.toLowerCase()}`)}
            salutation={translate(salutationTranslationKeyDeliveryAddress)}
            address={orderData.deliveryAddress}
            title={translate('web20_checkout_address_delivery_form_heading')}
            isDelivery
          />
        </div>
      </div>
      <h4 className="h4">
        <Translation id="web20_checkout_summary_basket_table_heading" />
      </h4>
      <OrderContents orderData={orderData} voucher={voucher} isSummaryPage />

      {/* Footer button row */}
      <div
        className={classNameBuilder(
          'cell grid-x grid-margin-x',
          styles.footerButtonRow,
          styles.topMargin,
          styles.bottomMargin,
        )}
      >
        <Button
          type={BUTTON_TYPES.DEFAULT}
          className="cell"
          symbol="arrow-link-light-right"
          color={BUTTON_COLORS.WHITE}
          background={BUTTON_BACKGROUND.PRIMARY}
          onClick={() => {
            window.location.href = webshopHomeUrl ?? '';
          }}
        >
          {translate('web20_checkout_summary_button_next')}
        </Button>
      </div>
    </>
  );
}
