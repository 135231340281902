import { useContext, useEffect } from 'react';

// types
import { Point } from '../../locator.types';
import {
  clampPointBottom,
  clampPointLeft,
  clampPointRight,
  clampPointTop,
  isPointBottomFromMap,
  isPointLeftFromMap,
  isPointRightFromMap,
  isPointTopFromSearchbar,
} from '../config';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';
import { RefsContext } from '../providers';
import { translateCenter } from '../utils/translate-pin';

export function useRecenterMap(map: google.maps.Map | null, point: Point | undefined) {
  const { mapRef, popupRef } = useContext(RefsContext);
  const isDesktop = useIsDesktop({ gdds: true });

  useEffect(() => {
    if (!map || !mapRef.current || !popupRef.current || !isDesktop || !point) {
      return;
    }

    const center = map.getCenter();
    if (!center) {
      return;
    }

    let translatedCenter: google.maps.LatLng | null | undefined;
    const { height: popupHeight, width: popupWidth } = popupRef.current.getBoundingClientRect();

    if (isPointTopFromSearchbar(point, popupHeight) || isPointLeftFromMap(point, popupWidth)) {
      translatedCenter = translateCenter(
        map,
        center,
        clampPointLeft(point, popupWidth),
        clampPointTop(point, popupHeight),
      );
    }

    const { width: mapWidth, height: mapHeight } = mapRef.current.getBoundingClientRect();
    if (isPointRightFromMap(point, mapWidth, popupWidth)) {
      translatedCenter = translateCenter(
        map,
        center,
        clampPointRight(point, mapWidth, popupWidth),
        0,
      );
    }

    if (isPointBottomFromMap(point, mapHeight)) {
      translatedCenter = translateCenter(map, center, 0, clampPointBottom(point, mapHeight));
    }

    if (translatedCenter) {
      map.setCenter(translatedCenter);
    }
  }, [isDesktop, point, map, mapRef, popupRef]);
}
