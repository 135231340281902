import { trackTemplate } from '../../../utils/tracking';

/**
 * Video start
 * @param string text
 */
function videoStart(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Videos',
      action: 'Video Initiate',
      label: text,
      value: '0',
    },
  });
}

/**
 * Video Complete
 * @param string text
 */
function videoComplete(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Videos',
      action: 'Video Complete',
      label: text,
      value: '0',
    },
  });
}

/**
 * Video progress
 * @param string text
 */
function videoProgress(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Videos',
      action: 'Viewing progress',
      label: text,
      value: '0',
    },
  });
}

export function trackMIVideo(
  videoTrackRef,
  playerApi,
  title,
  videoId,
  track: {
    trackEvent: (data: any) => void;
  },
) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  let videoStarted = false;

  playerApi.registerEventListener('play', () => {
    const currentTime = playerApi.getCurrentTime();
    if (currentTime === 0 && videoStarted === false) {
      track.trackEvent(videoStart(`${title} / ${videoId}`));
      videoStarted = true;
    }
  });
  playerApi.registerEventListener('ended', () => {
    track.trackEvent(videoComplete(`${title} / ${videoId}`));
    videoTrackRef.current = 0;
  });
  playerApi.registerEventListener('timeupdate', () => {
    const currentTime = playerApi.getCurrentTime();
    const totalDuration = playerApi.getDuration();
    const percentage = Math.floor((currentTime * 100) / totalDuration);
    if (videoTrackRef.current < 25 && percentage >= 25) {
      videoTrackRef.current = 25;
      track.trackEvent(videoProgress(`${title} / ${videoId} / ${videoTrackRef.current}%`));
    }
    if (videoTrackRef.current < 50 && percentage >= 50) {
      videoTrackRef.current = 50;
      track.trackEvent(videoProgress(`${title} / ${videoId} / ${videoTrackRef.current}%`));
    }
    if (videoTrackRef.current < 75 && percentage >= 75) {
      videoTrackRef.current = 75;
      track.trackEvent(videoProgress(`${title} / ${videoId} / ${videoTrackRef.current}%`));
    }
  });

  return null;
}
