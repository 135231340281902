import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  grid-area: buttons;
  display: flex;
  column-gap: 1rem;

  @media ${({ theme }) => theme.device.medium} {
    column-gap: 1.5rem;
  }

  > button {
    flex-grow: 1;
  }
`;
