import React, { useEffect, useState } from 'react';
import key from 'weak-key';
import { Button, Row } from '@geberit/gdds';

// styles
import styles from '../dlc-nordics.module.scss';

// types
import type { TilesAreaProps } from '../../types';

// components
import { Translation } from 'components/Translation/Translation';
import Tile from './tile';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

export default function TilesAreaNord(props: Readonly<TilesAreaProps>) {
  const {
    results,
    downloads,
    orders,
    handleLoadMore,
    updateOrder,
    updateDownload,
    lastPage = false,
    dateFormat,
    containerRef,
  } = props;
  const [hideLoadMore, setHideLoadMore] = useState(lastPage);

  const onLoadMore = () => {
    setHideLoadMore(true);
    handleLoadMore();
  };

  useEffect(() => {
    setHideLoadMore(lastPage);
  }, [results]);

  return (
    <>
      <div className={styles.tilesContainer} ref={containerRef}>
        <Row>
          {results.map((tile) => (
            <Tile
              key={key(tile)}
              dateFormat={dateFormat}
              updateOrder={updateOrder}
              updateDownload={updateDownload}
              downloads={downloads}
              tileData={tile}
              hasOrder={!!orders.find((order) => order.id === tile.id)}
            />
          ))}
        </Row>
      </div>
      <div className={classNameBuilder(styles.loadMore, hideLoadMore && styles.loadMoreHidden)}>
        <Button onClick={onLoadMore} symbol="Reload">
          <Translation id="web20_downloadcenter_more_results" />
        </Button>
      </div>
    </>
  );
}
