import { useState, useEffect } from 'react';
import { Typo } from '@geberit/gdds';

// styles
import styles from './downloadcenter.module.scss';

// types
import type { DownloadcenterCategoryProps } from '../types';

// components
import DownloadcenterListItem from './dlc-list-item';

// utils
import { categoryHeadingSize } from './utils/sizes';

export default function DownloadcenterCategory(props: Readonly<DownloadcenterCategoryProps>) {
  const {
    items,
    updateDownloads,
    updateOrders,
    categoryName,
    downloads,
    orders,
  } = props;
  // Hooks
  const [uniqueItems, setUniqueItems] = useState([]);

  useEffect(() => {
    if (items) {
      const tempUniqueItems = [];
      items.forEach((item) => {
        const found = tempUniqueItems.find((element) => element.id === item.id);
        if (!found) {
          tempUniqueItems.push(item);
        }
      });
      setUniqueItems(tempUniqueItems);
    }
  }, [items]);

  return (
    <>
      {uniqueItems.length > 0 && (
        <div className={styles.categoryContainer}>
          <Typo
            tag="div"
            size={categoryHeadingSize}
            fontWeight={600}
            className={styles.categoryHeadline}
          >
            {categoryName}
          </Typo>
          {uniqueItems.length > 0 &&
            uniqueItems.map((item) => (
              <DownloadcenterListItem
                key={item.id}
                item={item}
                setDownloadCounter={() => updateDownloads(item)}
                setOrderCounter={() => updateOrders(item)}
                downloads={downloads}
                orders={orders}
              />
            ))}
        </div>
      )}
    </>
  );
}
