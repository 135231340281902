import { useCallback } from 'react';
import { useIsoLang } from 'scenes/MetaData';

export function useCountryByIsoCode() {
  const country = useIsoLang().split('_').at(0)?.toLowerCase();

  return useCallback(
    (isoCode?: string) => {
      if (!country || !isoCode) return '';
      const countryNames = new Intl.DisplayNames([country], {
        type: 'region',
      });
      return countryNames.of(isoCode);
    },
    [country],
  );
}
