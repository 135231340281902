import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useTracking } from 'utils/tracking/track';
import { FilterContext } from './filter-provider';

export const PinAndListInteractionContext = createContext({} as ReturnType<typeof useValue>);

const useValue = () => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined);
  const { trackLocator } = useTracking();
  const { filteredEntries } = useContext(FilterContext);

  useEffect(() => {
    if (activeIndex) {
      const dealer = filteredEntries.find((loc) => loc.index === activeIndex);
      if (dealer) {
        trackLocator({
          click_intent: 'locator details',
          search_term: dealer.name,
          value: 2,
        });
      }
    }
  }, [activeIndex, filteredEntries, trackLocator]);

  return {
    activeIndex,
    setActiveIndex,
    hoveredIndex,
    setHoveredIndex,
  };
};

export const PinAndListInteractionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const value = useValue();

  return (
    <PinAndListInteractionContext.Provider value={value}>
      {children}
    </PinAndListInteractionContext.Provider>
  );
};
