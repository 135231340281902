import { trackTemplate } from '../../../utils/tracking';

/**
 * Accordion Click
 * @param string category Category name
 * @param string label Accordion item label
 */
export function accordionClick(category, label) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category,
      action: 'Accordion',
      label,
      value: '0',
    },
  });
}
