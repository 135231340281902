import { useState } from 'react';

// types
import type { SlideVideoProps } from '.';

// components
import { MiVideoContainer } from 'components/Video/miVideo/MiVideoContainer';
import VideoLightbox from 'components/lightbox/video-lightbox';

// utils
import { useTranslationByKey } from 'utils/hooks/use-translations';
import { isEmpty } from 'utils/is-empty';

const renameKey = (key, newKey, object) => {
  if (isEmpty(object)) {
    return {};
  }

  return Object.keys(object).reduce((composed, productKey) => {
    const value = Object.keys(object).find((oKey) => oKey === productKey);
    if (!value) return composed;
    const valueObject = object[value];

    if (productKey === key) {
      return {
        ...composed,
        [newKey]: valueObject,
      };
    }

    return {
      ...composed,
      [productKey]: valueObject,
    };
  }, {});
};

/**
 * Renders Video slide with lightbox
 */
const SlideVideo = ({ slide, sliderOnClickCTA, onPlay, onPause }: SlideVideoProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const videoId = slide.video?.videoId ?? '';
  const videoConformSlide = renameKey('headline', 'videoTitle', slide);
  const videoPlayButtonLabel = useTranslationByKey('web20_video_play_button');

  return (
    videoConformSlide && (
      <div onPlay={onPlay} onPause={onPause} className="video-preview-container">
        <MiVideoContainer
          content={videoConformSlide}
          className="video-preview-container"
          hasModal
          openModal={() => {
            sliderOnClickCTA?.(videoPlayButtonLabel);
            setModalIsOpen(true);
          }}
        />
        {modalIsOpen && (
          <VideoLightbox
            isModal
            key={`lightbox-${videoId}`}
            videoId={videoId}
            onClose={() => setModalIsOpen(false)}
          />
        )}
      </div>
    )
  );
};

export default SlideVideo;
