import _sortBy from 'lodash.sortby';
import { Col, Row } from '@geberit/gdds';

// styles
import styles from '../dlc-nordics.module.scss';

// types
import type { FilterAreaNordProps } from '../../types';

// components
import FilterNord from './filter';

// utils
import { classNameBuilder } from 'utils/classNameBuilder';

const GRID_COLS = {
  1: 3,
  2: 6,
  3: 8,
  4: 10,
};
const GRID_FILTER_WIDTHS = {
  1: 12,
  2: 6,
  3: 4,
  4: 3,
};

export default function FilterAreaNord(props: Readonly<FilterAreaNordProps>) {
  const {
    data: { aggregations },
    handleFilterChange,
    activeFilters = {},
  } = props;
  const filtersLength = Object.keys(aggregations).length;

  return (
    <form onSubmit={(e) => handleFilterChange('', e)} id="form1" key="form1" className={styles.filterForm}>
      <Row className={classNameBuilder(styles.centeredCol, styles.filterRow)}>
        <Col size={[4, 6, GRID_COLS[filtersLength]]}>
          <Row>
            {aggregations &&
              Object.keys(aggregations).map((filterKey) => (
                <Col
                  key={filterKey}
                  size={[4, 4, GRID_FILTER_WIDTHS[filtersLength]]}
                  className={styles.filter}
                >
                  <FilterNord
                    key={filterKey}
                    filterKey={filterKey}
                    options={_sortBy(aggregations[filterKey], [(o) => o.value.toLowerCase()])}
                    handleFilterChange={handleFilterChange}
                    activeItems={activeFilters[filterKey]}
                  />
                </Col>
              ))}
          </Row>
        </Col>
      </Row>
    </form>
  );
}
