import { Dropdown, Row, Col, Typo } from '@geberit/gdds';

// styles
import styles from '../dlc-nordics.module.scss';

// types
import type { SorterAreaNordProps } from '../../types';

// components
import { Translation } from 'components/Translation/Translation';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

function SorterAreaNord({ handleSortChange, totalResults }: Readonly<SorterAreaNordProps>) {
  const translate = useTranslationFunction();

  return (
    <Row>
      <Col size={[4, 8, 12]}>
        <div className={styles.tileFilters}>
          <div className={styles.results}>
            {totalResults !== undefined && (
              <Typo variant="p1" fontWeight={700}>
                {`${totalResults} `}
                <Translation id="web20_downloadcenter_filter_sum" />
              </Typo>
            )}
          </div>
          <div className={styles.sort}>
            <Dropdown
              items={[
                {
                  id: 'date',
                  value: 'date',
                  text: translate('web20_downloadcenter_filter_date'),
                },
                {
                  id: 'meta:title',
                  value: 'meta:title',
                  text: translate('web20_downloadcenter_filter_title'),
                },
              ]}
              onChange={handleSortChange}
              name={''}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
}

export default SorterAreaNord;
