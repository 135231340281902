import { useState, useEffect } from 'react';

export function usePermissionsStatus() {
  const [permissionStatus, setPermissionStatus] = useState<PermissionStatus | undefined>(undefined);
  const [isPermitted, setIsPermitted] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    navigator.permissions
      ?.query({ name: 'geolocation' })
      .then((p) => {
        setPermissionStatus(p);
      })
      .catch(() => {
        setPermissionStatus(undefined);
      });
  }, []);

  useEffect(() => {
    if (!permissionStatus) {
      return;
    }

    const permissionChangeHandler = () => {
      setIsPermitted(permissionStatus.state === 'granted');
    };

    permissionStatus.addEventListener('change', permissionChangeHandler);

    return () => {
      permissionStatus.removeEventListener('change', permissionChangeHandler);
    };
  }, [permissionStatus]);

  return isPermitted;
}
