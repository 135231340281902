import { useMemo } from 'react';

// types
import { AutocompletePrediction } from 'components/ContentElements/Locator/locator.types';

// utils
import { useSearchParamsState } from 'utils/hooks/use-search-params-state';

export function useRecentPredictionsStore(): AutocompletePrediction[] {
  const [{ addr }] = useSearchParamsState(['addr']);

  return useMemo(() => {
    const recentResultsString = localStorage.getItem('locator.recentResults');
    let recentResults: string[] = [];
    if (recentResultsString !== null) {
      recentResults = JSON.parse(recentResultsString) as string[];
    }

    if (addr) {
      const idx = recentResults.indexOf(addr);
      if (idx === -1) {
        recentResults.unshift(addr);
      } else {
        recentResults = [addr, ...recentResults.slice(0, idx), ...recentResults.slice(idx + 1)];
      }

      localStorage.setItem('locator.recentResults', JSON.stringify(recentResults.slice(0, 5)));
    }

    return recentResults.map((p) => {
      const data = p.split(',');

      return {
        structured_formatting: {
          main_text: data[0],
          secondary_text: data.slice(1).join(',').trim(),
        },
      } as AutocompletePrediction;
    });
  }, [addr]);
}
