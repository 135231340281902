import React from 'react';

// types
import { IEntry, ILocation } from '../../locator.types';

// utils
import { sortByDistance } from '../utils/pins-manager';
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';

export function useFitBounds(map: google.maps.Map | null, entries: IEntry[], location?: ILocation) {
  const center = location?.coordinates ?? location?.geoCoordinates;
  const initalPins = useLocatorConfiguration()?.initialPins ?? 10;

  React.useEffect(() => {
    if (map === null || center === undefined) {
      return;
    }

    const bounds = new google.maps.LatLngBounds(center);
    // entries may sorted with partner priority, but for the map we only wan't to display
    // the next 10 pins around our location. so we resort by distance only and add
    // the pins to the bounds
    entries
      .slice()
      .sort(sortByDistance)
      .slice(0, initalPins)
      .forEach((pin) => {
        bounds.extend(pin);
      });

    map.fitBounds(bounds);
  }, [map, entries, initalPins, center]);
}
