/**
 * Method to truncate a given string to a specified length and a specified seperator.
 * @param {string} string Target string to truncate.
 * @param {number} maxLength Specified max length for the result string.
 * @param {string} separator Seperator to set between split parts (www.geberit.c ... /aquaclean)
 * @returns {string} Truncated string based on length and seperator.
 */
export function truncateMiddle(string: string, maxLength: number, separator: string) {
  if (string.length <= maxLength) {
    return string;
  }

  const stringSplit = string.split('');
  const front = stringSplit.slice(0, 29).join('');
  const back = stringSplit.slice(stringSplit.length - 28, stringSplit.length).join('');
  return `${front}${separator}${back}`;
}

/**
 *
 * @param {*} text
 * @param {*} maxLength
 * @param {*} ellipsis
 */
export function truncateAtWordBoundary<T = string>(text: T, maxLength: number, ellipsis = '…'): T {
  return typeof text === 'string'
    ? (text.replace(new RegExp(`^(.{${maxLength}}[^\\s]*).*`), `$1${ellipsis}`) as T)
    : text;
}

/**
 *
 * @param {*} text
 * @param {*} maxLength
 * @param {*} ellipsis
 */
export const truncateAtSpecificChar = (text: string, maxLength: number, ellipsis = '…') =>
  typeof text === 'string' ? `${text.substring(0, maxLength)}${ellipsis}` : text;
