import { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

// types
import type { VideoPreviewImageType } from '../ContentElementsGdds/video-channel/types';

// components
import VideoLightbox from '../lightbox/video-lightbox';
import { Translation } from '../Translation/Translation';
import { InnerHtml } from '../InnerHtml/inner-html';

// utils
import { useUCConsent, VideoConsent } from 'features/cookie-consent';
import { isEmpty } from 'utils/is-empty';
import { baseURLSelector } from 'utils/selectors/globalsSelectors';
import { useHash } from 'utils/hooks/use-hash';

type VideoItemProps = {
  video: {
    title: string;
    description: string;
    channelUrl: string;
    video: {
      type: string;
      videoId: string;
      videoPreviewImage: VideoPreviewImageType<never>;
      videoTitle: string;
    };
  };
  screenSize?: string;
  open?: boolean;
};

export default function VideoItem({
  screenSize = 'small',
  open = false,
  video: {
    title,
    description,
    channelUrl,
    video: { type, videoId, videoPreviewImage, videoTitle },
  },
}: Readonly<VideoItemProps>) {
  const hash = useHash();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const ucConsent = useUCConsent();
  const videoItemRef = useRef<HTMLDivElement | null>(null);
  const baseURL = useSelector(baseURLSelector);

  let previewImage = '';

  const handleOpen = (e?: any) => {
    if (e) e.preventDefault();
    setModalIsOpen(true);
  };

  useEffect(() => {
    if (hash === videoId && !modalIsOpen && open && videoItemRef.current) {
      videoItemRef.current.scrollIntoView();
      handleOpen();
    }
  }, [open, hash]);

  if (isEmpty(videoId)) return null;

  const truncateAtWordBoundary = (text, maxLength, ellipsis = '…') =>
    text.replace(new RegExp(`^(.{${maxLength}}[^\\s]*).*`), `$1${ellipsis}`);

  if (!isEmpty(videoPreviewImage)) {
    previewImage =
      screenSize === 'small'
        ? videoPreviewImage.mobile
        : videoPreviewImage.desktop || videoPreviewImage.mobile;
  }

  if (ucConsent.Comfort === false) {
    return (
      <div className="c-video-center__item" id={`${videoId}`}>
        <VideoConsent type={type} />
      </div>
    );
  }

  return (
    <div className="c-video-center__item" id={`${videoId}`}>
      <div
        className={`image ${ucConsent.Comfort === false ? 'noconsent' : undefined}`}
        style={{ backgroundImage: `url(${previewImage})` }}
        role="img"
        aria-label={videoTitle}
        title={videoTitle}
      >
        <div
          role="button"
          tabIndex={0}
          className="description"
          onClick={handleOpen}
          onKeyPress={handleOpen}
          ref={videoItemRef}
        >
          <InnerHtml as="p" content={truncateAtWordBoundary(description, 150)} />
          {ucConsent.Comfort && (
            <button type="button" className="button">
              <Translation id="web20_video_play_button" />
            </button>
          )}
        </div>
      </div>

      <div className="text">
        <InnerHtml as="h3" content={title} />
      </div>

      {modalIsOpen && (
        <VideoLightbox
          isModal
          videoId={videoId}
          channelUrl={`${channelUrl}#${videoId}`}
          videoUrl={`${baseURL}${channelUrl}#${videoId}`}
          title={title}
          text={description}
          onClose={() => setModalIsOpen(false)}
        />
      )}
    </div>
  );
}
