import type { Voucher } from 'utils/hooks/useVoucher';
import { VoucherInfo } from 'components/voucher-info/voucher-info';
import { VoucherDisplay } from 'components/voucher-form/voucher-display';

export type VoucherProps = {
  voucher: Voucher;
  /** If true there will be no inputs to modify the voucher */
  displayOnly: boolean;
};

export function Voucher({ voucher, displayOnly }: VoucherProps) {
  const {
    voucherCode,
    appliedVoucher,
    voucherInfo,
    voucherErrorMessage,
    handleVoucherInputChange,
    applyVoucher,
    removeVoucher,
  } = voucher;

  return (
    <>
      <VoucherInfo voucherInfo={voucherInfo} voucherErrorMessage={voucherErrorMessage} />

      <VoucherDisplay
        voucherCode={voucherCode}
        appliedVoucher={appliedVoucher}
        handleVoucherInputChange={handleVoucherInputChange}
        applyVoucher={applyVoucher}
        removeVoucher={removeVoucher}
        displayOnly={displayOnly}
      />
    </>
  );
}
