import {
  BUTTON_ALIGNMENT,
  BUTTON_BACKGROUND,
  BUTTON_COLORS,
  Button,
} from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';

interface LoadMoreButtonProps {
  handleLoadMore: () => void;
}

function LoadMoreButton({ handleLoadMore }: Readonly<LoadMoreButtonProps>) {
  return (
    <Button
      key="button"
      color={BUTTON_COLORS.WHITE}
      background={BUTTON_BACKGROUND.PRIMARY}
      symbol="reload"
      align={BUTTON_ALIGNMENT.CENTER}
      onClick={handleLoadMore}
      className="loadmore-button"
    >
      <Translation id="web20_more_results" />
    </Button>
  );
}

export default LoadMoreButton;
