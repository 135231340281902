export const TPP_TYPE_IMAGE = 'image';
export const TPP_TYPE_PAGE = 'page';
export const TPP_TYPE_TRANSLATION = 'translation';
export const TPP_TYPE_NEWSECTION = 'newsection';
export const TPP_TYPE_TECHNICAL = 'technical';
export const TPP_TYPE_VIDEOCHANNEL = 'videochannel';
export const TPP_TYPE_DOWNLOADCENTER = 'downloads';
export const TPP_TYPE_DOWNLOAD = 'download';
export const TPP_TYPE_TILES = 'tiles';
export const TPP_TYPE_SLIDE = 'slide';
export const TPP_TYPE_META_NAVIGATION = 'meta_navigation';
export const TPP_TYPE_SHORTURL = 'shorturls';
export const TPP_TYPE_EVENTS = 'events';
export const TPP_TYPE_TRANSLATION_STATICPAGE = 'translation_static';
