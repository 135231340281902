import React, { useState } from 'react';
import Dropdown, { DropdownContent, DropdownTrigger } from 'react-simple-dropdown';
import key from 'weak-key';

// Compontens
import { Translation } from '../Translation/Translation';
import { InnerHtml } from '../InnerHtml/inner-html';
import { decodingContent } from 'utils/decodingContent';

interface TableOfContentsViewProps {
  hidePlaceholder: boolean;
  dropdownRef: any;
  placeholder: string | null;
  type: string | null;
  handleDropdownClick: (e: any, id: string) => void;
  items: any[];
}

function TableOfContentsView({
  hidePlaceholder = false,
  dropdownRef = null,
  placeholder = null,
  handleDropdownClick,
  type = null,
  items = [],
}: Readonly<TableOfContentsViewProps>) {
  const filteredItems = type !== 'product' ? items.filter((item) => item.titleInAnchor) : items;
  const [selectedItem, setSelectedItem] = useState<string | undefined>(undefined);

  if (filteredItems.length < 1) {
    return null;
  }

  return (
    <div className="grid-container c-table-of-content--wrapper">
      <Dropdown className="c-table-of-content" ref={dropdownRef}>
        <DropdownTrigger>
          <i className="icon web20-icon web20-icon-chevron-large-down" />
          {!hidePlaceholder ? (
            <span>
              <Translation id="web20_topic" /> <InnerHtml as="span" content={placeholder} />
            </span>
          ) : (
            <ul>
              {items.map((item) => (
                <li key="listItem">
                  <span>
                    <Translation id="web20_topic" />{' '}
                    {item.name.substr(0, 60).replace(/<[^>]+>|\(%%.*%%\)/g, ' ')}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </DropdownTrigger>
        <DropdownContent>
          <ul>
            {filteredItems.map((item) => (
              <li key={key(item)} className={item.id === selectedItem ? 'is--active' : undefined}>
                <a
                  key="link"
                  href={`#${item.id}`}
                  onClick={(e) => {
                    setSelectedItem(item.id);
                    handleDropdownClick(e, item.id);
                  }}
                >
                  {decodingContent(item.name)}
                </a>
              </li>
            ))}
          </ul>
        </DropdownContent>
      </Dropdown>
    </div>
  );
}

export default TableOfContentsView;
