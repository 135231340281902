import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Marker } from '@react-google-maps/api';

// types
import type { IEntry } from '../locator.types';
import type { IconMode } from './utils/icon-builder';

// utils
import { iconBuilder } from './utils/icon-builder';
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { PinAndListInteractionContext } from './providers/pin-and-list-interaction-provider';
import { useIsDesktop } from 'components/App/SizeProvider';
import { catalogBrandNameSelector } from 'utils/selectors/globalsSelectors';
import { mapBrand } from '../utils/map-brand';

interface MarkerElementProps {
  location: IEntry;
  isActive: boolean;
  onSelect: () => void;
}

export function MarkerElement({ location, isActive, onSelect }: Readonly<MarkerElementProps>) {
  // Here it will be decided later where the brand
  // name will be read from (config, CRM data etc.)
  const brandName = useSelector(catalogBrandNameSelector);
  // If brand name is read from CRM Data, then we
  // do not need the variable and the brand
  // attribute in the iconBuilder function argument object.
  // We would then read brand from the location attribute.
  const brand = mapBrand(brandName);

  const [isMouseOver, setIsMouseOver] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const locatorConfiguration = useLocatorConfiguration();
  const { hoveredIndex, setHoveredIndex } = useContext(PinAndListInteractionContext);
  const isDesktop = useIsDesktop();

  // proper if statement to avoid nested ternary
  let mode: IconMode;
  if (isActive || isMouseDown) {
    mode = 'active';
  } else {
    mode = isMouseOver || hoveredIndex === location.index ? 'hover' : 'default';
  }

  const handleMouseOver = () => {
    setIsMouseOver(true);
    if (isDesktop) {
      setHoveredIndex(location.index);
    }
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
    setIsMouseDown(false);
    if (isDesktop) {
      setHoveredIndex(undefined);
    }
  };

  return (
    <Marker
      zIndex={1}
      position={location}
      icon={iconBuilder({
        location,
        brand,
        mode,
        showPartner: Boolean(locatorConfiguration?.filter?.showPartnerSwitch),
      })}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseDown={() => {
        setIsMouseDown(true);
      }}
      onMouseUp={() => {
        setIsMouseDown(false);
      }}
      onClick={onSelect}
    />
  );
}
