interface ContentAreaPlaceholderProps {
  contentType: string;
  editType?: string;
  editArea?: string;
  previewId?: string;
}

export function ContentAreaPlaceholder({
  contentType,
  editType,
  editArea,
  previewId,
}: Readonly<ContentAreaPlaceholderProps>) {
  return (
    <div>
      <div className="grid-container">
        <div
          className="c-tpp-area__placeholder"
          data-edit-type={editType}
          data-edit-area={editArea}
          data-preview-id={previewId}
        >
          {contentType}
          <em>(empty)</em>
        </div>
      </div>
    </div>
  );
}
