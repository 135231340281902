import { useContext } from 'react';

// utils
import { FilterContext } from '../map';
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { decodingContent } from 'utils/decodingContent';

export function useControlButtons() {
  const locatorConfig = useLocatorConfiguration();
  const { filteredEntries, setShowFilter, filters, resetFilters } = useContext(FilterContext);
  const activeFilters = Object.values(filters).filter(Boolean);

  return {
    apply: {
      stylingType: 'primary' as const,
      children: `${decodingContent(locatorConfig?.filter?.apply || '')} ${
        activeFilters.length !== 0 ? `(${filteredEntries.length})` : ''
      }`,
      onClick: () => setShowFilter(false),
    },
    reset: {
      stylingType: 'secondary' as const,
      children: decodingContent(locatorConfig?.filter?.reset),
      disabled: activeFilters.length === 0,
      onClick: resetFilters,
    },
  };
}
