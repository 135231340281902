export function latlngToPixels(
  map: google.maps.Map,
  zoom: number | undefined,
  latLng: google.maps.LatLngLiteral,
) {
  const projection = map.getProjection();
  const bounds = map.getBounds();
  if (!projection || !bounds) {
    return undefined;
  }

  const topRight = projection.fromLatLngToPoint(bounds.getNorthEast());
  const bottomLeft = projection.fromLatLngToPoint(bounds.getSouthWest());
  const worldPoint = projection.fromLatLngToPoint(latLng);
  if (!topRight || !bottomLeft || !worldPoint || !zoom) {
    return undefined;
  }

  const scale = Math.pow(2, zoom);

  return {
    x: Math.floor((worldPoint.x - bottomLeft.x) * scale),
    y: Math.floor((worldPoint.y - topRight.y) * scale),
  };
}
