import { Message } from '../Message/Message';
import { EditWrapper } from '../ContentCreator/EditWrapper';

export const ComponentHasError = ({ type, isPreview, previewId }) => {
  if (!isPreview) return null;

  return (
    <div className="grid-container">
      <EditWrapper previewId={previewId}>
        <Message closable={false} type="warning" content={`Section "${type}" has an error.`} />
      </EditWrapper>
    </div>
  );
};
