import { trackTemplate } from '../../utils/tracking';

/**
 * TOC anchor click
 * @param string text
 */
export function anchorClick(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Clicks',
      action: 'Anchors',
      label: text,
      value: '0',
    },
  });
}
