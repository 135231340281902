import { Log } from 'services/log';

interface ICoordinate {
  lat: number;
  lng: number;
}

export function getCoordinates(): Promise<ICoordinate> {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      return reject(new Error('Geolocation not supported'));
    }

    const options = {
      enableHighAccuracy: true,
      maximumAge: 0,
      timeout: 8000,
    };

    const success: PositionCallback = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      resolve({ lat: latitude, lng: longitude });
    };

    const error: PositionErrorCallback = () => {
      reject(new Error('Error retrieving location'));
    };

    navigator.geolocation.getCurrentPosition(success, error, options);
  });
}

export async function geocode(
  address: string,
  country: string,
): Promise<
  | {
      coordinates: ICoordinate;
      address: string;
      address_components: google.maps.GeocoderAddressComponent[];
    }
  | undefined
> {
  try {
    const { results } = await new window.google.maps.Geocoder().geocode({
      address,
    });
    results.reverse();
    const { geometry, formatted_address, address_components } = results.find(
      ({ formatted_address }, index) => {
        return (
          formatted_address.toLowerCase().includes(address.toLowerCase()) ||
          index === results.length - 1
        );
      },
    ) ?? { geometry: undefined, formatted_address: undefined, address_components: undefined };
    if (geometry?.location) {
      const { lat, lng } = geometry.location;
      return {
        coordinates: { lat: lat(), lng: lng() },
        address: formatted_address,
        address_components,
      };
    }
    return undefined;
  } catch (error) {
    Log.error('Failed to geocode address', error);
    return undefined;
  }
}

export async function geodecode({
  lng,
  lat,
}: ICoordinate): Promise<{ geoCoordinates: ICoordinate; address: string } | undefined> {
  try {
    const { results } = await new window.google.maps.Geocoder().geocode({
      location: { lng, lat },
    });
    const [{ formatted_address }] = results;

    if (formatted_address) {
      return { geoCoordinates: { lat, lng }, address: formatted_address };
    }
    return undefined;
  } catch (error) {
    Log.error('Failed to geodecode coordinates', error);
    return undefined;
  }
}
