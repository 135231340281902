// types
import { DealerType, IEntry } from '../locator.types';

// utils
import { ILocatorConfiguration } from 'utils/selectors/globalsSelectors';
import { decodingContent } from 'utils/decodingContent';

export const isDealerPartner = (partnertype: IEntry['partnertype']) =>
  partnertype?.toLowerCase().startsWith('p') || false;

export const isDealerGeberit = (partnertype: IEntry['partnertype']) =>
  partnertype?.toLowerCase().startsWith('g') || false;

export const getDealerCategory = (
  entry: IEntry,
): Lowercase<DealerType> | Lowercase<DealerType>[] => {
  const type = entry.partnertype?.toLowerCase().slice(-1) as Lowercase<DealerType>;
  if (type === 'i' && entry.products.length > 0) {
    return ['i', 's'];
  }

  return type;
};

export const getDealerCategoryName = (entry: IEntry, config: ILocatorConfiguration): string => {
  let labelsKey = '';

  if (isDealerGeberit(entry.partnertype)) {
    labelsKey = 'brandShowroom';
  } else {
    const result = getDealerCategory(entry);
    if (Array.isArray(result)) {
      labelsKey = 'installerWithShowroom';
    } else if (result === 'i') {
      labelsKey = 'installer';
    } else {
      labelsKey = 'dealerWithShowroom';
    }
  }

  return decodingContent(config?.labels?.[labelsKey]) || labelsKey;
};
