import React, { PropsWithChildren, useRef } from 'react';

export const RefsContext = React.createContext({} as ReturnType<typeof useValue>);

const useValue = () => {
  const locatorWrapperRef = useRef<HTMLDivElement | null>(null);
  const portalRef = useRef<HTMLDivElement | null>(null);
  const transparentLayerRef = useRef<HTMLDivElement | null>(null);
  const mapRef = useRef<HTMLDivElement | null>(null);
  const popupRef = useRef<HTMLDivElement | null>(null);
  const hoverPopupRef = useRef<HTMLDivElement | null>(null);

  return {
    portalRef,
    mapRef,
    transparentLayerRef,
    locatorWrapperRef,
    popupRef,
    hoverPopupRef,
  };
};

export const RefsContextProvider = ({ children }: PropsWithChildren<any>) => {
  return <RefsContext.Provider value={useValue()}>{children}</RefsContext.Provider>;
};
