import React from 'react';

export const PlumberIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_4954_143694)">
      <path
        d="M10.3401 1.87145C9.46398 0.998265 8.33944 0.417179 7.12039 0.207737C5.90133 -0.00170404 4.64733 0.170736 3.53006 0.701451L7.53006 4.70145L4.69006 7.51145L0.690057 3.51145C0.192378 4.54921 0.00367352 5.70821 0.146445 6.85025C0.289217 7.99228 0.757441 9.06916 1.49528 9.95246C2.23312 10.8358 3.20944 11.4882 4.30782 11.832C5.4062 12.1758 6.58028 12.1964 7.69006 11.8915L11.7901 15.9915L12.4901 15.2815L8.69006 11.5215C9.30157 11.2307 9.85945 10.8385 10.3401 10.3615C10.8243 9.87717 11.2203 9.31201 11.5101 8.69145L15.2901 12.4615L16.0001 11.7615L11.8901 7.65145C12.1601 6.63664 12.1591 5.56872 11.8871 4.55442C11.6151 3.54013 11.0816 2.61498 10.3401 1.87145ZM9.64006 9.65145C8.89704 10.3869 7.94219 10.8709 6.9098 11.0355C5.8774 11.2 4.81939 11.0369 3.88454 10.5689C2.9497 10.1009 2.18506 9.35169 1.69815 8.42657C1.21124 7.50146 1.02655 6.44699 1.17006 5.41145L4.69006 8.93145L8.93006 4.69145L5.42006 1.18145C6.4539 1.04309 7.50513 1.23152 8.42655 1.72035C9.34798 2.20918 10.0935 2.97394 10.5586 3.90754C11.0238 4.84114 11.1853 5.89684 11.0206 6.92682C10.8559 7.95679 10.3732 8.90947 9.64006 9.65145Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4954_143694">
        <rect width="16" height="16" fill="none" />
      </clipPath>
    </defs>
  </svg>
);
