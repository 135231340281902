import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';

// types
import type { AutocompletePrediction } from 'components/ContentElements/Locator/locator.types';

// utils
import { FilterContext } from '../../map';
import { Log } from 'services/log';
import { useIsoCountry } from 'scenes/MetaData';
import { isDefined } from 'utils/ts-utilities';

export function usePredictions(value: string) {
  const service = useRef(new window.google.maps.places.AutocompleteService()).current;
  const [predictions, setPredictions] = useState<AutocompletePrediction[]>([]);
  const { countries } = useContext(FilterContext);
  const isoCountry = useIsoCountry().toLowerCase();
  const [debouncedValue] = useDebounce(value, 250);

  const country = useMemo(() => {
    // countries is not defined when using locator tile or locator teaser section
    if (countries && countries.length > 0) {
      const countryCodes = countries.map((c) => c?.toLowerCase()).filter(isDefined);
      return countryCodes.length <= 5 ? countryCodes : null;
    }

    return isoCountry;
  }, [isoCountry, countries]);

  useEffect(() => {
    if (debouncedValue.length > 2) {
      service
        .getPlacePredictions({
          input: debouncedValue,
          componentRestrictions: {
            country,
          },
        })
        .then(({ predictions }) => {
          setPredictions(predictions);
        })
        .catch(() => {
          Log.error('Failed to load predictions');
          setPredictions([]);
        });
    }
  }, [country, service, debouncedValue]);

  return predictions;
}
