import React from 'react';

export const ShowroomIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clipPath="url(#clip0_4954_143691)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.33 0H1.67L0 3.9V7H1V16H15V7H16V3.9L14.33 0ZM10 15H6V10H10V15ZM14 15H11V9H5V15H2V7H14V15ZM15 5V6H13V4H12V6H10V4H9V6H7V4H6V6H4V4H3V6H1V4.1L2.33 1H13.67L15 4.1V5Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_4954_143691">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
