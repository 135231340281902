import styled from 'styled-components';
import { Typo } from '@geberit/gdds';

export const StyledElement = styled.li<{ focused: boolean }>`
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid transparent;
  list-style: none;
  height: 2.5rem;
  display: flex;
  align-items: center;
  padding: 0;

  & > span {
    margin-right: 0.5rem;
  }

  ${({ theme, focused }) => (focused ? `border: 1px solid ${theme.colors.black}` : '')};

  && > p {
    padding: 0;
    margin: 0;

    .highlight {
      font-weight: 700;
    }

    > label {
      margin-left: 0.5rem;
      color: ${({ theme }) => theme.colors.grey13};
    }
  }
`;

export const StyledFlyoutText = styled(Typo)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem !important;
`;
