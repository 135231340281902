import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { useRouter } from 'next/navigation';

// types
import type { Dispatch } from 'redux';
import type { AddressFormProps } from './AddressForm.types';

// styles
import styles from './AddressFormStyles.module.scss';

// components
import { Button, BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES } from 'components/Button/Button';
import FormikInputField from '../FormikInputField';
import FormikSelect from '../FormikSelect';
import { Translation } from 'components/Translation/Translation';

// utils
import { isEmpty } from 'utils/is-empty';
import { invoiceAddressSelector, deliveryAddressSelector } from '../../selectors';
import { createFormObject, userType } from '../../util/helperFunctions';
import {
  changeActiveStepAction,
  postSimulatedOrderAction,
  setDeliveryAddressAction,
  setInvoiceAddressAction,
  zipCodeValidationAction,
} from '../../actions';
import { translationKeysSelector } from 'utils/selectors/translationSelectors';
import { webshopBasketPageUrlSelector } from 'utils/selectors/globalsSelectors';
import { getInitialValues, safeJsonParse, validate } from './utils';
import { MY_DATA } from '../../constants';

const AddressForm = ({
  isLogged,
  userData,
  ciamId,
  sessionId,
  userDataError,
  locale,
  setIsDeliveryCheckboxSelected,
  zipCodeValidationFieldName,
  zipCodeValidationError,
  zipCodeValidationData,
  deliveryZipCodeValidationData,
  isFetchingZipCodeValidation,
  disableButton,
  disableButtonDelivery,
}: Readonly<AddressFormProps>) => {
  const dispatch: Dispatch<any> = useDispatch();
  const invoiceAddress = useSelector(invoiceAddressSelector);
  const deliveryAddress = useSelector(deliveryAddressSelector);
  const translations = useSelector(translationKeysSelector);
  const basketPageUrl = useSelector(webshopBasketPageUrlSelector);
  const router = useRouter();

  const setFieldValRef = useRef<(name: string, value: string) => void>();
  const countryAbbreviation = locale.split('-')[1].toLowerCase();
  const savedAddresses = localStorage.getItem('addresses') ?? '';

  const initialValues = getInitialValues({
    isLoggedIn: isLogged,
    invoiceAddress: isEmpty(invoiceAddress)
      ? safeJsonParse(savedAddresses, { invoiceAddress: {} })?.invoiceAddress
      : invoiceAddress,
    userData,
    deliveryAddress: isEmpty(deliveryAddress)
      ? safeJsonParse(savedAddresses, { deliveryAddress: {} })?.deliveryAddress
      : deliveryAddress,
    countryAbbreviation,
  });

  type FormikValues = typeof initialValues;

  const sessionStorageData = window.sessionStorage.getItem(MY_DATA);
  const sessionData = sessionStorageData ? JSON.parse(sessionStorageData) : null;
  const sessionTypeData = sessionData ? sessionData[userType(isLogged)] : null;

  const persistData = (values: FormikValues): void => {
    window.sessionStorage.setItem(
      MY_DATA,
      JSON.stringify({ ...sessionData, [isLogged ? 'user' : 'guest']: { ...values } }),
    );
  };

  useEffect(() => {
    if (sessionTypeData) {
      const addressObject = createFormObject(sessionTypeData);
      const deliveryAddress = {
        deliveryCheckbox: sessionTypeData.deliveryCheckbox,
        ...addressObject.deliveryAddress,
      };
      dispatch(
        setInvoiceAddressAction({
          sapCustomerNumber: userData.sapCustomerNumber,
          ...addressObject.invoiceAddress,
        }),
      );
      dispatch(setDeliveryAddressAction(deliveryAddress));
    }
  }, [sessionStorageData]);

  useEffect(() => {
    const onAnimationStart = (e: AnimationEvent) => {
      if (e.animationName === 'onautofillstart' && typeof setFieldValRef.current === 'function') {
        setFieldValRef.current(e?.target?.['name'], e?.target?.['value']);
      }
    };

    const onInput = (e: InputEvent) => {
      if (
        (e.inputType === 'insertReplacementText' || !('data' in e)) &&
        typeof setFieldValRef.current === 'function'
      ) {
        const value =
          e?.target?.['type'] === 'checkbox' ? e?.target?.['checked'] : e?.target?.['value'];
        setFieldValRef.current(e?.target?.['name'], value);
      }
    };

    window.document.addEventListener('animationstart', onAnimationStart, true);
    window.document.addEventListener('input', onInput, true);

    return () => {
      window.document.removeEventListener('animationstart', onAnimationStart);
      window.document.removeEventListener('input', onInput);
    };
  }, []);

  const selectOptionsByLocaleOrUserData = (userCountry?: string) => {
    if (locale && !isEmpty(translations)) {
      if (
        locale.includes('CH') ||
        (userCountry && (userCountry.includes('CH') || userCountry.includes('LI')))
      ) {
        return (
          <>
            <option value="CH">{translations.countries_ch}</option>
            <option value="LI">{translations.countries_li}</option>
          </>
        );
      }
      if (
        locale.includes('FR') ||
        (userCountry && (userCountry.includes('FR') || userCountry.includes('MC')))
      ) {
        return (
          <>
            <option value="FR">{translations.countries_fr}</option>
            <option value="MC">{translations.countries_mc}</option>
          </>
        );
      }
      return (
        <option value={countryAbbreviation.toUpperCase()}>
          {translations[`countries_${countryAbbreviation}`]}
        </option>
      );
    }
    return null;
  };

  const handleZipCodeValidation = (code, country, fieldName) => {
    if (!code) return;
    const body = {
      code,
      country,
    };
    dispatch(zipCodeValidationAction(body, fieldName));
  };

  async function onSubmitHandler(values: FormikValues) {
    const addressObject = createFormObject(values);

    const deliveryAddress = {
      deliveryCheckbox: values.deliveryCheckbox,
      ...addressObject.deliveryAddress,
    };

    const id = isLogged ? ciamId : sessionId;

    dispatch(
      setInvoiceAddressAction({
        sapCustomerNumber: userData.sapCustomerNumber,
        ...addressObject.invoiceAddress,
      }),
    );
    dispatch(setDeliveryAddressAction(deliveryAddress));
    dispatch(
      postSimulatedOrderAction(
        locale,
        id ?? '',
        {
          invoiceAddress: addressObject.invoiceAddress,
          deliveryAddress,
        },
        isLogged,
        () => {
          persistData(values);
        },
      ),
    );
    dispatch(changeActiveStepAction(1));
    persistData(values);
    localStorage.setItem('addresses', JSON.stringify(addressObject));
  }

  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        if (isFetchingZipCodeValidation) return;
        handleZipCodeValidation(values.zip, values.country, 'zip');
        return validate(values, translations);
      }}
      validateOnBlur={true}
      onSubmit={onSubmitHandler}
    >
      {(values) => {
        const { errors, touched, setFieldValue } = values;
        setFieldValRef.current = setFieldValue;

        return (
          <Form className={styles.form} id="form">
            <div className="grid-x">
              <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                <FormikSelect
                  name="salutation"
                  value={values.values.salutation}
                  setFieldValue={setFieldValue}
                >
                  <option value="" disabled>
                    {translations?.web20_checkout_address_salutation}
                  </option>
                  <option value="0002">{translations?.web20_checkout_address_salutation_1}</option>
                  <option value="0001">{translations?.web20_checkout_address_salutation_2}</option>
                  <option value="9008">{translations?.web20_checkout_address_salutation_3}</option>
                </FormikSelect>
              </div>
            </div>
            <div className="grid-x">
              <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                {/* First Name */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="firstName"
                  id="firstName"
                  maxLength={35}
                  placeholder={`${translations?.web20_checkout_address_first_name_label} *`}
                  error={touched.firstName && errors.firstName}
                  currentValue={values.values.firstName}
                />
              </div>
              <div className={`small-12 medium-6 ${styles.formRightElement}`}>
                {/* Last Name */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="lastName"
                  id="lastName"
                  maxLength={35}
                  placeholder={`${translations?.web20_checkout_address_last_name_label} *`}
                  error={touched.lastName && errors.lastName}
                  currentValue={values.values.lastName}
                />
              </div>
            </div>

            <div className="grid-x">
              <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                {/* Email */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="email"
                  id="email"
                  maxLength={241}
                  placeholder={`${translations?.web20_checkout_address_email_label} *`}
                  error={touched.email && errors.email}
                  isDisabled={isLogged && !userDataError}
                  currentValue={values.values.email}
                />
              </div>
            </div>

            <div className="grid-x">
              <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                {/* PhoneNumber */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="phone"
                  id="phone"
                  maxLength={16}
                  placeholder={translations?.web20_checkout_address_phone_number_label}
                  error={touched.phone && errors.phone}
                  currentValue={values.values.phone}
                />
              </div>

              <div className={`small-12 medium-6 ${styles.formRightElement}`}>
                {/* Mobile PhoneNumber */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="mobile"
                  id="mobile"
                  maxLength={16}
                  placeholder={translations?.web20_checkout_address_mobile_phone_number_label}
                  error={touched.mobile && errors.mobile}
                  currentValue={values.values.mobile}
                />
              </div>
            </div>

            <div className="grid-x">
              <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                {/* Company */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="company"
                  id="company"
                  maxLength={35}
                  error={touched.company && errors.company}
                  placeholder={translations?.web20_checkout_address_company_label}
                  currentValue={values.values.company}
                />
              </div>
            </div>

            <div className="grid-x">
              <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                {/* Street and house */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="street"
                  id="street"
                  maxLength={35}
                  error={touched.street && errors.street}
                  placeholder={`${translations?.web20_checkout_address_street_and_house_label} *`}
                  currentValue={values.values.street}
                />
              </div>
              <div className={`small-12 medium-6 ${styles.formRightElement}`}>
                {/* Additional Address */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="street2"
                  id="street2"
                  maxLength={35}
                  placeholder={translations?.web20_checkout_address_addition}
                  error={touched.street2 && errors.street2}
                  currentValue={values.values.street2}
                />
              </div>
            </div>

            <div className="grid-x">
              <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                {/* Zip code */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="zip"
                  id="zip"
                  maxLength={10}
                  placeholder={`${translations?.web20_checkout_address_zip_code_label} *`}
                  error={
                    zipCodeValidationFieldName === 'zip' &&
                    ((touched.zip && errors.zip) ||
                    zipCodeValidationError ||
                    (!isEmpty(zipCodeValidationData) && !zipCodeValidationData.valid)
                      ? `${translations?.web20_checkout_address_error_message_1} ${translations?.web20_checkout_address_zip_code_label}`
                      : '')
                  }
                  currentValue={values.values.zip}
                />
              </div>
              <div className={`small-12 medium-6 ${styles.formRightElement}`}>
                {/* City */}
                <FormikInputField<FormikValues>
                  type="text"
                  name="city"
                  id="city"
                  maxLength={35}
                  placeholder={`${translations?.web20_checkout_address_city_label} *`}
                  error={touched.city && errors.city}
                  currentValue={values.values.city}
                />
              </div>
            </div>

            <div className="grid-x">
              <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                {/* Country */}
                <FormikSelect
                  name="country"
                  value={values.values.country}
                  setFieldValue={setFieldValue}
                >
                  {selectOptionsByLocaleOrUserData(values.initialValues.country)}
                </FormikSelect>
              </div>
            </div>

            <div className={`grid-x ${styles.deliveryCheckboxWrapper}`}>
              <label htmlFor="deliveryCheckbox">
                <Field
                  type="checkbox"
                  id="deliveryCheckbox"
                  name="deliveryCheckbox"
                  className={styles.inputCheckbox}
                  defaultChecked={values.values.deliveryCheckbox}
                  onClick={() => {
                    setFieldValue('deliveryCheckbox', !values.values.deliveryCheckbox);
                    setIsDeliveryCheckboxSelected(!values.values.deliveryCheckbox);
                  }}
                />
                <span>
                  <Translation id="web20_checkout_address_delivery_checkbox_label" />
                </span>
              </label>
            </div>

            {values.values.deliveryCheckbox && (
              <>
                <h3 className={styles.subTitle}>
                  <Translation id="web20_checkout_address_delivery_form_heading" />
                </h3>
                <div className="grid-x">
                  <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                    <FormikSelect
                      name="deliverySalutation"
                      value={values.values.deliverySalutation}
                      setFieldValue={setFieldValue}
                    >
                      <option value="" disabled>
                        {translations?.web20_checkout_address_salutation}
                      </option>
                      <option value="0002">
                        {translations?.web20_checkout_address_salutation_1}
                      </option>
                      <option value="0001">
                        {translations?.web20_checkout_address_salutation_2}
                      </option>
                      <option value="9008">
                        {translations?.web20_checkout_address_salutation_3}
                      </option>
                    </FormikSelect>
                  </div>
                </div>

                <div className="grid-x">
                  <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                    {/* First Name */}
                    <FormikInputField<FormikValues>
                      type="text"
                      name="deliveryFirstName"
                      id="deliveryFirstName"
                      maxLength={35}
                      placeholder={`${translations?.web20_checkout_address_first_name_label} *`}
                      error={touched.deliveryFirstName && errors.deliveryFirstName}
                      currentValue={values.values.deliveryFirstName}
                    />
                  </div>
                  <div className={`small-12 medium-6 ${styles.formRightElement}`}>
                    {/* Last Name */}
                    <FormikInputField<FormikValues>
                      type="text"
                      name="deliveryLastName"
                      id="deliveryLastName"
                      maxLength={35}
                      placeholder={`${translations?.web20_checkout_address_last_name_label} *`}
                      error={touched.deliveryLastName && errors.deliveryLastName}
                      currentValue={values.values.deliveryLastName}
                    />
                  </div>
                </div>

                <div className="grid-x">
                  <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                    {/* Company */}
                    <FormikInputField<FormikValues>
                      type="text"
                      name="deliveryCompany"
                      id="deliveryCompany"
                      maxLength={35}
                      error={touched.deliveryCompany && errors.deliveryCompany}
                      placeholder={translations?.web20_checkout_address_company_label}
                      currentValue={values.values.deliveryCompany}
                    />
                  </div>
                </div>

                <div className="grid-x">
                  <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                    {/* Street and house */}
                    <FormikInputField<FormikValues>
                      type="text"
                      name="deliveryStreetAndHouse"
                      id="deliveryStreetAndHouse"
                      maxLength={35}
                      error={touched.deliveryStreetAndHouse && errors.deliveryStreetAndHouse}
                      placeholder={`${translations?.web20_checkout_address_street_and_house_label} *`}
                      currentValue={values.values.deliveryStreetAndHouse}
                    />
                  </div>
                  <div className={`small-12 medium-6 ${styles.formRightElement}`}>
                    {/* Additional Address */}
                    <FormikInputField<FormikValues>
                      type="text"
                      name="deliveryStreet2"
                      id="deliveryStreet2"
                      maxLength={35}
                      placeholder={translations?.web20_checkout_address_addition}
                      error={touched.deliveryStreet2 && errors.deliveryStreet2}
                      currentValue={values.values.deliveryStreet2}
                    />
                  </div>
                </div>

                <div className="grid-x">
                  <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                    {/* Zip code */}
                    <FormikInputField<FormikValues>
                      type="text"
                      name="deliveryZipCode"
                      id="deliveryZipCode"
                      maxLength={10}
                      placeholder={`${translations?.web20_checkout_address_zip_code_label} *`}
                      error={
                        zipCodeValidationFieldName === 'deliveryZipCode' &&
                        ((touched.deliveryZipCode && errors.deliveryZipCode) ||
                        zipCodeValidationError ||
                        (!isEmpty(deliveryZipCodeValidationData) &&
                          !deliveryZipCodeValidationData.valid)
                          ? `${translations?.web20_checkout_address_error_message_1} ${translations?.web20_checkout_address_zip_code_label}`
                          : '')
                      }
                      currentValue={values.values.deliveryZipCode}
                      inputBlur={() => {
                        handleZipCodeValidation(
                          values.values.deliveryZipCode,
                          values.values.deliveryCountry,
                          'deliveryZipCode',
                        );
                      }}
                    />
                  </div>
                  <div className={`small-12 medium-6 ${styles.formRightElement}`}>
                    {/* City */}
                    <FormikInputField<FormikValues>
                      type="text"
                      name="deliveryCity"
                      id="deliveryCity"
                      maxLength={35}
                      placeholder={`${translations?.web20_checkout_address_city_label} *`}
                      error={touched.deliveryCity && errors.deliveryCity}
                      currentValue={values.values.deliveryCity}
                    />
                  </div>
                </div>

                <div className="grid-x">
                  <div className={`small-12 medium-6 ${styles.formLeftElement}`}>
                    {/* Country */}
                    <FormikSelect
                      name="deliveryCountry"
                      value={values.values.deliveryCountry}
                      onChange={(country) => {
                        handleZipCodeValidation(
                          values.values.deliveryZipCode,
                          country,
                          'deliveryZipCode',
                        );
                      }}
                      setFieldValue={setFieldValue}
                    >
                      {selectOptionsByLocaleOrUserData()}
                    </FormikSelect>
                  </div>
                </div>
              </>
            )}

            <div className={styles.buttonsWrapper}>
              <Button
                type={BUTTON_TYPES.DEFAULT}
                symbol="arrow-link-light-left"
                color={BUTTON_COLORS.BLACK}
                background={BUTTON_BACKGROUND.WHITE}
                onClick={() => router.push(basketPageUrl ?? '')}
                className={styles.editButton}
              >
                {translations?.web20_checkout_address_button_back}
              </Button>
              <Button
                disabled={
                  isFetchingZipCodeValidation ||
                  zipCodeValidationError ||
                  disableButton ||
                  disableButtonDelivery
                }
                buttonType="submit"
                symbol="arrow-link-light-right"
                color={BUTTON_COLORS.WHITE}
                background={BUTTON_BACKGROUND.PRIMARY}
                id="addressFormSubmitBtn"
              >
                {translations?.web20_checkout_address_button_next}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddressForm;
