import React from 'react';
import { Button } from '@geberit/gdds';

// styles
import { ButtonsContainer } from './control-buttons.styles';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';
import { useControlButtons } from './control-buttons.hooks';

export function ControlButtons() {
  const { apply, reset } = useControlButtons();
  const isDesktop = useIsDesktop({ gdds: true });

  if (isDesktop) {
    return null;
  }

  return (
    <ButtonsContainer>
      <Button {...reset} />
      <Button {...apply} />
    </ButtonsContainer>
  );
}
