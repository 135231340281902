import { isEmpty } from './is-empty';

export function responsiveImage<TypeMobile = ImageObjectMobile, TypeDesktop = ImageObject>(
  isMobile: boolean,
  mobileObject: TypeMobile,
  desktopObject: TypeDesktop,
): TypeMobile | TypeDesktop {
  return isEmpty(mobileObject) || !(mobileObject as ImageObjectMobile).url || !isMobile
    ? desktopObject
    : mobileObject;
}
