import { Dialogue } from '@geberit/gdds';

// types
import type { VideoModalContentProps } from './video.types';

// components
import { MiVideoContainer } from 'components/Video/miVideo/MiVideoContainer';
import ConfigurableYoutube from 'components/Video/youtube/configurable-youtube';

// utils
import { decodingContent } from 'utils/decodingContent';

export default function VideoModalContent({
  handleClose,
  videoId,
  playerControl,
  language,
  showLink,
  title,
  isYoutubeVideo,
  hasVideoError = false,
  videoReady = false,
}: Readonly<VideoModalContentProps>) {
  return (
    <Dialogue
      body={
        isYoutubeVideo ? (
          <ConfigurableYoutube
            videoId={videoId}
            playerControl={playerControl}
            language={language}
            showLink={showLink}
          />
        ) : (
          videoReady && (
            <MiVideoContainer
              hasError={hasVideoError}
              isModal
              className="c-text-video"
              content={{ video: { videoId } }}
            />
          )
        )
      }
      showCloseBtn
      type="custom"
      size="l"
      titleLabel={decodingContent(title)}
      fullscreen
      closeDialogue={handleClose}
    />
  );
}
