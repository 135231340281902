import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Button, Notification } from '@geberit/gdds';

// styles
import {
  MapSearchBox,
  StyledMapPlaceholder,
  StyledMapPortal,
  StyledMapWrapper,
  StyledMap,
  TransparentLayer,
  CSSVariables,
} from './map.styles';

// components
import { SearchContainer, SearchContext } from '../search';
import { MarkerElement } from './pin-marker';
import { Filter, FilterCloseButton } from '../filter';
import { List } from './list';
import { DealerPopup } from './dealer-popup/dealer-popup';
import { Note } from '../detail-page/note/note';

// types
import type { IEntryWithKey } from './providers/filter-provider';
import type { IWithDistance } from './utils/pins-manager';
import type { Point } from '../locator.types';

// utils
import { useRecenterMap } from './hooks/use-recenter-map';
import { latlngToPixels } from './utils/latlng-to-pixels';
import { useOutsideClick } from 'utils/hooks/use-outside-click';
import { useFitBounds } from './hooks/use-bounds';
import { useIsDesktop } from 'components/App/SizeProvider';
import { FilterContext, RefsContext } from './providers';
import { useBodyScroll } from '../utils/use-body-scroll';
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { translateCenter } from './utils/translate-pin';
import { useAnimation } from './hooks/use-animation';
import { PORTAL_GAP, useScrollHandler } from './hooks/use-scroll-handler';
import { CUSTOM_MAP_STYLES } from './utils/custom-map-styles';
import { PinAndListInteractionContext } from './providers/pin-and-list-interaction-provider';
import { useSyncEntries } from './hooks/use-sync-list';

export function MapWrapper() {
  const { location, error, resetError } = useContext(SearchContext);

  const { showFilter, setShowFilter, saveAppliedFilters, restoreSavedFilters, filteredEntries } =
    useContext(FilterContext);

  const [activePin, setActivePin] = useState<IWithDistance<IEntryWithKey> | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoom, setZoom] = useState<number | undefined>();
  const [newZoom, setNewZoom] = useState<number | undefined>();
  const [newCenter, setNewCenter] = useState<google.maps.LatLng | undefined>();
  const [popupPosition, setPopupPosition] = useState<Point | undefined>(undefined);
  const [hoveredItem, setHoveredItem] = useState<IWithDistance<IEntryWithKey> | null>(null);
  const [hoverPopupPosition, setHoverPopupPosition] = useState<Point | undefined>(undefined);

  const { locatorWrapperRef, mapRef, portalRef, transparentLayerRef, popupRef, hoverPopupRef } =
    useContext(RefsContext);

  const locatorConfig = useLocatorConfiguration();
  const isDesktop = useIsDesktop({ gdds: true });
  const { hoveredIndex, activeIndex, setActiveIndex } = useContext(PinAndListInteractionContext);

  const listItems = useSyncEntries(map, filteredEntries);
  useScrollHandler(activePin);
  useFitBounds(map, filteredEntries, location);
  useBodyScroll(showFilter || (!isDesktop && Boolean(activePin)));
  useAnimation(activePin);
  useRecenterMap(map, popupPosition);
  useOutsideClick(mapRef, () => {
    if (isDesktop) {
      resetActivePin();
    }
  });

  useEffect(() => {
    if (map && isDesktop) {
      if (activePin) {
        setPopupPosition(latlngToPixels(map, zoom, activePin));
      }
      if (hoveredItem) {
        setHoverPopupPosition(latlngToPixels(map, zoom, hoveredItem));
      }
    }
  }, [map, isDesktop, activePin, zoom, hoveredItem]);

  useEffect(() => {
    if (isDesktop || activePin) {
      return;
    }
    if (newCenter) {
      map?.setCenter(newCenter);
    }
    if (newZoom) {
      map?.setZoom(newZoom);
    }
  }, [map, isDesktop, activePin, newCenter, newZoom]);

  useEffect(() => {
    const hoveredItem = filteredEntries.find((item) => item.index === hoveredIndex);
    setHoveredItem(hoveredItem ?? null);
  }, [filteredEntries, hoveredIndex]);

  const translatedActivePin = useMemo(() => {
    if (isDesktop || !map || !activePin) {
      return undefined;
    }

    return translateCenter(map, activePin, 0, 200);
  }, [map, isDesktop, activePin]);

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const getMarkerOnSelectHandler = useCallback(
    (loc: IWithDistance<IEntryWithKey>) => () => {
      if (!isDesktop && !activePin) {
        setNewCenter(map?.getCenter());
        setNewZoom(map?.getZoom());
      }

      setActivePin(activePin?.key === loc.key ? null : loc);
      setActiveIndex(loc.index);
    },
    [isDesktop, activePin, setActiveIndex, map],
  );

  const onCenterChangedHandler = useCallback(() => {
    if (map && isDesktop && activePin) {
      setPopupPosition(latlngToPixels(map, zoom, activePin));
    }
  }, [map, isDesktop, activePin, zoom]);

  const onDealerSelectedHandler = useCallback(
    (pin: IWithDistance<IEntryWithKey>) => {
      setActivePin(pin);
      setNewCenter(map?.getCenter());
      setNewZoom(map?.getZoom());
      map?.panTo(pin);
      !isDesktop && map?.setZoom(16);
    },
    [isDesktop, map],
  );

  const resetActivePin = () => {
    setActivePin(null);
    setActiveIndex(undefined);
  };

  const center = location?.coordinates ?? location?.geoCoordinates;

  if (!center) {
    return null;
  }

  return (
    <CSSVariables portalGap={`${PORTAL_GAP}px`}>
      <Filter visible={isDesktop || showFilter} />
      <StyledMapWrapper ref={locatorWrapperRef}>
        <StyledMapPortal ref={portalRef}>
          <StyledMap ref={mapRef} active={Boolean(activePin)}>
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={{
                fullscreenControl: false,
                streetViewControl: false,
                mapTypeControl: false,
                styles: CUSTOM_MAP_STYLES,
              }}
              center={translatedActivePin ?? center}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={resetActivePin}
              onZoomChanged={() => {
                setZoom(map?.getZoom());
              }}
              onCenterChanged={onCenterChangedHandler}
            >
              <Marker position={center} icon="/images/locator/location-marker.svg" zIndex={0} />
              {filteredEntries.map((loc) => (
                <MarkerElement
                  key={loc.key}
                  isActive={!!activePin && loc.key === activePin.key}
                  location={loc}
                  onSelect={getMarkerOnSelectHandler(loc)}
                />
              ))}
            </GoogleMap>
            {error && (
              <Notification
                type="broadcast"
                text={locatorConfig?.consent?.geoLocationFailureNote || ''}
                buttonStyleType="flatInverted"
                showCloseButton
                positionTop={0}
                onClick={resetError}
                className="notification"
              />
            )}
            <DealerPopup
              ref={popupRef}
              location={location}
              dealer={activePin}
              onClose={resetActivePin}
              point={popupPosition}
              closeable
            />
            {hoveredIndex && hoveredIndex !== activeIndex && (
              <DealerPopup
                ref={hoverPopupRef}
                location={location}
                dealer={hoveredItem}
                point={hoverPopupPosition}
              />
            )}
          </StyledMap>
          <TransparentLayer
            ref={transparentLayerRef}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          />
        </StyledMapPortal>
        <StyledMapPlaceholder />
        <MapSearchBox sticky={!showFilter}>
          <SearchContainer />
          {!isDesktop && (
            <Button
              className="filter-button"
              isIcon
              symbol="Filter"
              stylingType="iconOutline"
              onClick={() => {
                saveAppliedFilters();
                setShowFilter(!showFilter);
              }}
            />
          )}
          {!isDesktop && showFilter && (
            <FilterCloseButton
              stylingType="icon"
              isIcon
              symbol="Close"
              onClick={() => {
                restoreSavedFilters();
                setShowFilter(false);
              }}
            />
          )}
        </MapSearchBox>
        <List onDealerSelected={onDealerSelectedHandler} location={location} items={listItems} />
        <Note />
      </StyledMapWrapper>
    </CSSVariables>
  );
}
