// types
import type { VideoPreviewImageType } from 'components/ContentElementsGdds/video-channel/types';

// components
import { Title } from '../Title/Title';
import { InnerHtml } from 'components/InnerHtml/inner-html';
import { MiVideoContainer } from 'components/Video/miVideo/MiVideoContainer';
import ConfigurableYoutube from '../../Video/youtube/configurable-youtube';

// utils
import { TitleFormats } from '../Title/title.types';
import { useSectionId } from 'utils/hooks/use-section-id';

type TextVideoProps = {
  headlinePosition: 'content' | 'top';
  title: string;
  text: string;
  video: {
    videoPreviewImage: VideoPreviewImageType<string>;
    videoId: string;
    playerControl: boolean;
    language: string;
    showLink: boolean;
    type: 'youtube' | 'miVideo';
  };
  position?: 'left' | 'right';
};

export function TextVideo({
  headlinePosition = 'top',
  title,
  text,
  video,
  position = 'left',
}: Readonly<TextVideoProps>) {
  const sectionId = useSectionId(title, '');

  if (!video) {
    return null;
  }

  return (
    <div className="grid-container" id={sectionId}>
      <div className="c-text-video">
        {headlinePosition !== 'content' && (
          <Title
            title={title}
            Format={TitleFormats.h2}
            titlePreviewId="#st_title"
            subtitlePreviewId="#st_subtitle"
            formatClassName="h3"
          />
        )}
        <div className={`c-text-video__wrapper c-text-video__wrapper--${position}`}>
          {video?.type === 'youtube' ? (
            <ConfigurableYoutube
              videoId={video?.videoId}
              playerControl={video?.playerControl}
              language={video?.language}
              showLink={video?.showLink}
            />
          ) : (
            <MiVideoContainer className="c-text-video" content={{ video }} />
          )}
          {headlinePosition === 'content' ? (
            <div className="c-text-video__text">
              <Title title={title} Format={TitleFormats.h2} formatClassName="h3" />
              {text && <InnerHtml as="p" content={text} />}
            </div>
          ) : (
            text && (
              <InnerHtml
                as="div"
                className="c-text-video__text"
                content={text}
                previewId="#st_text"
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}
