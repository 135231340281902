import React, { useContext, useEffect, useMemo, useState } from 'react';

// components
import { Search } from './search';
import { SearchContext } from './search-provider';

// types
import { FlyoutDisplayMode } from '../locator.types';

// utils
import { useRecentPredictionsStore } from './utils/use-recent-predictions-store';
import { highlightPredictions } from './utils/highlight-predictions';
import { usePredictions } from './utils/use-predictions';
import { useLocatorConfiguration } from '../utils/use-locator-configuration';

export function SearchContainer() {
  const recentResults = useRecentPredictionsStore();
  const locatorSearch = useLocatorConfiguration();

  const { findLocation, geoLocated, geoLocationPending, locate, location } =
    useContext(SearchContext);

  const [value, setValue] = useState<string>(location?.address || '');
  const predictions = usePredictions(value);
  const flyoutDisplayMode: FlyoutDisplayMode = value ? 'suggestions' : 'recentResults';

  const suggestions = useMemo(() => {
    const hightLightOnlyMainText = flyoutDisplayMode === 'recentResults';

    return highlightPredictions(
      flyoutDisplayMode === 'suggestions' ? predictions : recentResults,
      hightLightOnlyMainText,
    );
  }, [flyoutDisplayMode, predictions, recentResults]);

  const flyoutLabel =
    flyoutDisplayMode === 'suggestions'
      ? locatorSearch?.search?.suggestionsLabel
      : locatorSearch?.search?.recentResultsLabel;

  const iconName = flyoutDisplayMode === 'suggestions' ? 'Location' : 'Clock';

  const handleOnSubmit = async (val: string) => {
    findLocation(val);
  };

  useEffect(() => {
    setValue(location?.address ?? '');
  }, [location?.geoCoordinates, location?.address]);

  return (
    <Search
      iconName={iconName}
      flyoutLabel={flyoutLabel}
      onSubmit={() => {
        handleOnSubmit(value);
      }}
      onChange={(val: string, final: boolean) => {
        setValue(val);
        if (final) {
          handleOnSubmit(val);
        }
      }}
      geoLocationPending={geoLocationPending && !geoLocated}
      geoLocated={geoLocated}
      suggestions={suggestions}
      name="zipOrCity"
      placeholder={locatorSearch?.search?.placeholder}
      value={value}
      onLocationClick={locate}
    />
  );
}
