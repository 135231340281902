import React from 'react';
import { FormField } from '@geberit/gdds';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { Translation } from 'components/Translation/Translation';
import styles from './voucher-display.module.scss';
import { isEmpty } from 'utils/is-empty';
import { Voucher } from 'utils/hooks/useVoucher';
import { VoucherProps } from 'components/Checkout/Components/Voucher/Voucher';
import { VoucherControl } from 'components/voucher-control/voucher-control';

export type VoucherFormProps = Pick<
  Voucher,
  'applyVoucher' | 'removeVoucher' | 'handleVoucherInputChange' | 'appliedVoucher' | 'voucherCode'
> &
  Pick<VoucherProps, 'displayOnly'>;

export const VoucherDisplay: React.FC<VoucherFormProps> = ({
  voucherCode,
  handleVoucherInputChange,
  applyVoucher,
  appliedVoucher,
  removeVoucher,
  displayOnly,
}) => {
  const translate = useTranslationFunction();

  const hasAppliedVoucher: boolean = !isEmpty(appliedVoucher);

  const onFormSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    applyVoucher();
  };

  const VoucherInput = (
    <div className={styles.voucherInput}>
      <FormField
        name="voucherCode"
        placeholder={translate('web20_checkout_voucher_placeholder')}
        values={{ voucherCode: voucherCode.toUpperCase() }}
        onChange={handleVoucherInputChange}
      />
    </div>
  );

  const VoucherLabel = (
    <div className={styles.voucherLabel}>
      <Translation id="web20_checkout_voucher_title" />
    </div>
  );

  if (displayOnly) {
    if (!hasAppliedVoucher) return null;

    return (
      <div className={styles.voucherDisplay}>
        {VoucherLabel}
        {<VoucherControl {...{ appliedVoucher: appliedVoucher!, removeVoucher, displayOnly }} />}
      </div>
    );
  }

  return (
    <div className={styles.voucherDisplay}>
      {VoucherLabel}
      <form className={styles.voucherForm} onSubmit={onFormSubmit}>
        {hasAppliedVoucher ? (
          <VoucherControl {...{ appliedVoucher: appliedVoucher!, removeVoucher, displayOnly }} />
        ) : (
          VoucherInput
        )}

        <button type="submit" className={styles.voucherSubmitButton} disabled={hasAppliedVoucher}>
          <Translation id="web20_checkout_voucher_activate" />
        </button>
      </form>
    </div>
  );
};
