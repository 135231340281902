import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

// types
import type { Fieldsets } from './nord/types';

interface FormGcaptchaProviderProps extends React.PropsWithChildren {
  fieldsets: Fieldsets;
  captchaKey: string | null;
}

export function FormGcaptchaProvider({
  fieldsets,
  captchaKey,
  children,
}: Readonly<FormGcaptchaProviderProps>) {
  const formFields = fieldsets?.fields;
  const hasCaptcha = formFields?.some?.((field) => field.type === 'form_captcha');

  if (hasCaptcha && captchaKey) {
    return <GoogleReCaptchaProvider reCaptchaKey={captchaKey}>{children}</GoogleReCaptchaProvider>;
  }

  return <>{children}</>;
}
