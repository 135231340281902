import { useId } from 'react';

/**
 * this hook generates a id that can be used as ref which is the same
 * on server and client side.
 * we removed the ':' from the id so we can use it as valid class selector
 */
export function useUniqueId() {
  return useId().replace(/:/g, '');
}
