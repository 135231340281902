import { trackTemplate } from 'utils/tracking';

export function clickAddToDownloadCart(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: 'Download Center Add to Download Basket',
      label: text,
      value: '0',
    },
  });
}

export function clickAddToOrderCart(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: 'Download Center Add to Order Basket',
      label: text,
      value: '0',
    },
  });
}

export function clickZipCart(text) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: 'Download Center ZIP',
      label: text,
      value: '0',
    },
  });
}

export function clickDlcFilter(url) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Filter',
      action: 'DownloadCenter',
      label: url,
      value: '0',
    },
  });
}

export function clickDownloadsLink(name, title) {
  return trackTemplate({
    event: 'genericEvent',
    data: {
      category: 'Download center',
      action: `Download Center ${name}`,
      label: title,
      value: '0',
    },
  });
}
