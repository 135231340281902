import styled from 'styled-components';

export const StyledIconWrapper = styled.div`
  width: 1.5rem;
  line-height: 0;
  display: flex;
  align-items: start;
`;

export const StyledContainer = styled.div`
  grid-area: primary;
  display: flex;
  flex-direction: row;

  button.selected {
    svg path {
      fill: inherit;
    }
  }
`;
