import React from 'react';
import { SystemNotification } from '@geberit/gdds';
import styles from './voucher-info.module.scss';
import { Voucher } from 'utils/hooks/useVoucher';

type VoucherInfoProps = Pick<Voucher, 'voucherInfo' | 'voucherErrorMessage'>;

export const VoucherInfo: React.FC<VoucherInfoProps> = ({ voucherInfo, voucherErrorMessage }) => {
  return (
    <>
      {voucherInfo && (
        <div className={styles.messageContainer}>
          <SystemNotification notificationType="info" text={voucherInfo} symbol="error1" />
        </div>
      )}
      {voucherErrorMessage && (
        <div className={styles.messageContainer}>
          <SystemNotification notificationType="error" text={voucherErrorMessage} symbol="error2" />
        </div>
      )}
    </>
  );
};
