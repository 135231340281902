import styled from 'styled-components';

export const StyledLabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;

  > label {
    :first-of-type:not(:last-of-type) {
      background-color: ${({ theme }) => theme.colors.primaryLight};
      color: ${({ theme }) => theme.colors.white};
      padding: 2px 0.5rem;

      @media print {
        print-color-adjust: exact;
        -webkit-print-color-adjust: exact;
      }
    }
  }
`;
