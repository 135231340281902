import { PostSimulatedOrderActionAddressParameter } from 'components/Checkout/actions';

const CHECKOUT_VOUCHER_STORAGE_KEY = 'CHECKOUT_VOUCHER';

type Order = Required<PostSimulatedOrderActionAddressParameter['order']>;

export type PersistedCheckoutVoucher = { order: Order };

export function resetPersistedCheckoutVoucher() {
  window.sessionStorage.removeItem(CHECKOUT_VOUCHER_STORAGE_KEY);
}

export function setPersistedCheckoutVoucher(order: Order) {
  const persistData: PersistedCheckoutVoucher = {
    order,
  };

  window.sessionStorage.setItem(CHECKOUT_VOUCHER_STORAGE_KEY, JSON.stringify(persistData));
}

export function getPersistedCheckoutVoucher(): PersistedCheckoutVoucher | null {
  try {
    const data = window.sessionStorage.getItem(CHECKOUT_VOUCHER_STORAGE_KEY);
    if (!data) return null;
    return JSON.parse(data);
  } catch (e) {
    console.error(`Failed to parse ${CHECKOUT_VOUCHER_STORAGE_KEY} data in sessionStorage`, e);
    return null;
  }
}
