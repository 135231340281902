import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// types
import type {
  DownloadCenterContainerProps,
  AdditionalContentProps,
  AdditionalPageProps,
  Order,
  Download,
} from '../types';

// components
import { Title } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import FilterArea from 'components/Filter/FilterArea';
import Cart from './cart/cart';
import NoResult from './no-result';
import TilesArea from './tile/tilesarea';
import SearchBar from 'components/SearchBar/SearchBar';
import SumAndSortArea from 'components/Filter/SumAndSortArea';
import IntroText from 'components/ContentElements/IntroText/IntroText';

// utils
import { fetchDownloadZip } from 'features/download-center/actions';
import { useDownloadCenter } from 'features/download-center/use-downloadcenter';
import { useDateFormat } from 'utils/hooks/use-dateformat';
import { useSessionState } from 'utils/hooks/use-storage-state';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { catalogBrandNameSelector } from 'utils/selectors/globalsSelectors';
import { hasContentAreas, useContent } from 'components/Page/page-hooks';

function DownloadCenterContainer({ initialData }: Readonly<DownloadCenterContainerProps>) {
  const [orders, setOrders] = useSessionState<Order[]>('orders', []);
  const [downloads, setDownloads] = useSessionState<Download[]>('downloads', []);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const containerRef = useRef();
  const translate = useTranslationFunction();
  const dispatch = useDispatch();
  const brandName = useSelector(catalogBrandNameSelector) ?? 'geberit';
  const content = useContent<AdditionalContentProps, AdditionalPageProps>();
  const preFilters = content?.page?.preFilters || [];


  const {
    data: downloadCenterData,
    loadMore: handleLoadMore,
    isLastPage: last,
    setSorting,
    setSearch: handleSearchSubmit,
    search,
    activeFilters,
    setFilters,
  } = useDownloadCenter(
    {
      prefilterTags: preFilters,
    },
    initialData,
  );

  const dateFormat = useDateFormat() || 'yyyy-MM-dd';

  if (!hasContentAreas(content)) {
    return null;
  }

  const { totalResults, q, results } = downloadCenterData || {};
  const {
    page: { headline, subline, text, disableSearchbar = false, disableUserFilters = false },
    contentAreas: { content: downloadForm },
  } = content;

  function updateOrder(product) {
    let value: Order[] = [];
    if (orders.some((order) => order.id === product.id)) {
      // delete item from cart if already added to cart
      value = orders.filter((order) => order.id !== product.id);
    } else {
      // add item to cart
      value = orders.concat([product]);
    }
    setOrders(value);
  }

  function updateDownload(product) {
    let value: Download[] = [];
    if (downloads.some((download) => download.id === product.id)) {
      // delete item from cart if already added to cart
      value = downloads.filter((order) => order.id !== product.id);
    } else {
      // add item to cart
      value = downloads.concat([product]);
    }
    setDownloads(value);
  }

  function updateAmount(newOrder) {
    const value = orders.map((order) => (order.id === newOrder.id ? newOrder : order));
    setOrders(value);
  }

  function updateDownloadProgess(loaded) {
    setDownloadProgress(loaded);
  }

  function downloadZip(download) {
    dispatch(fetchDownloadZip(download, setDownloadProgress, brandName));
  }

  function handleFilterChange(e) {
    const { target } = e;
    const filterKey = target.getAttribute('data-filterkey');
    const filterValue = target.getAttribute('value');
    const isAllFilter = target.getAttribute('data-allfilter');
    const id = target.getAttribute('id');
    const currentSelectedFilters = { ...activeFilters };

    /* Handles the selection of a different sorting value */
    if (filterKey === 'sorting') {
      setSorting(id);
      return false;
    }
    /* Handles the selection an all filter present in the different dropdowns */
    if (isAllFilter === true || isAllFilter === 'true') {
      const { [filterKey]: _, ...newSearchParams } = currentSelectedFilters;
      setFilters(newSearchParams);
      return false;
    }
    /* Adjusts the activeFilters in state to match up with the selected filter
     * from a filter dropdown.
     * filterKey indicated the dropdown meaning filter1 to filter4. Since
     * activeFilters are stored in the component's state, it gets retrieved and
     * iterated while getting modified to reflect the filter change.
     */
    if (filterKey in currentSelectedFilters) {
      if (Object.prototype.hasOwnProperty.call(currentSelectedFilters, filterKey)) {
        const index = currentSelectedFilters[filterKey].findIndex((item) => item === filterValue);
        if (index === 0) {
          if (currentSelectedFilters[filterKey].length === 1) {
            delete currentSelectedFilters[filterKey];
          } else {
            currentSelectedFilters[filterKey] = currentSelectedFilters[filterKey].filter(
              (_, i) => i !== index,
            );
          }
        } else if (index !== -1) {
          currentSelectedFilters[filterKey] = currentSelectedFilters[filterKey].filter(
            (_, i) => i !== index,
          );
        } else {
          currentSelectedFilters[filterKey].push(filterValue);
        }
      }
    } else {
      currentSelectedFilters[filterKey] = [filterValue];
    }
    setFilters(currentSelectedFilters);
    return null;
  }
  if (!hasContentAreas(content)) {
    return null;
  }

  return (
    <div className="grid-container">
      <div className="grid-x grid-margin-x dc-page-headline">
        <div className="cell small-12 medium-8 large-9">
          <Title Format={TitleFormats.h1} title={headline} subtitle={subline} hasIntroText />
          <IntroText text={text} grid={false} />
        </div>
        <Cart
          orders={orders}
          downloads={downloads}
          updateDownload={updateDownload}
          updateOrder={updateOrder}
          updateAmount={updateAmount}
          downloadZip={downloadZip}
          downloadProgress={downloadProgress}
          setDownloadProgress={setDownloadProgress}
          updateDownloadProgess={updateDownloadProgess}
          downloadForm={downloadForm}
          getSessionStorage={() => {
            setOrders([]);
          }}
        />
      </div>
      {!disableSearchbar && (
        <div className="c-downloadcenter">
          <div className="c-search-navigation">
            <SearchBar customHandler={handleSearchSubmit} initialQuery={search} />
          </div>
        </div>
      )}
      {Boolean(downloadCenterData.aggregations) && (
        <>
          {!disableUserFilters && (
            <FilterArea
              data={downloadCenterData}
              activeFilters={activeFilters}
              handleFilterChange={handleFilterChange}
            />
          )}
          <SumAndSortArea
            data={downloadCenterData}
            activeFilters={activeFilters}
            handleFilterChange={handleFilterChange}
          />
          <TilesArea
            dateFormat={dateFormat}
            updateOrder={updateOrder}
            updateDownload={updateDownload}
            handleLoadMore={handleLoadMore}
            results={results}
            orders={orders}
            downloads={downloads}
            lastPage={last}
            containerRef={containerRef}
          />
        </>
      )}
      {downloadCenterData && totalResults === 0 && (
        <NoResult query={q} headline={translate('web20_search_results')} />
      )}
    </div>
  );
}

export default DownloadCenterContainer;
