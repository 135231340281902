import * as yup from 'yup';

// utils
import { isEmpty } from 'utils/is-empty';

/**
 * Create yup conform validation object from fsrules
 *
 * @param rules fs rules
 * @returns {*}
 * @constructor
 */
export function validation(rules: ValidationRules['rules']) {
  // match create yup rules regarding the fs rules
  const yupValidation = (pattern, validator, errorMessage) => {
    const validationSchema = {
      NotEmptyFormValidator: yup
        .string()
        .ensure()
        .test('required', errorMessage, (value) => {
          return !isEmpty(String(value));
        }),
      ContainsFormValidator: yup
        .string()
        .ensure()
        .test('contains', errorMessage, (value) => String(value).includes(pattern)),
      NotContainsFormValidator: yup
        .string()
        .ensure()
        .test('notContains', errorMessage, (value) => !String(value).includes(pattern)),
      EqualsFormValidator: yup
        .string()
        .ensure()
        .test('equals', errorMessage, (value) => value === pattern),
      NotEqualsFormValidator: yup
        .string()
        .ensure()
        .test('not equals', errorMessage, (value) => value !== pattern),
      RegularExpressionFormValidator: yup
        .string()
        .ensure()
        .matches(new RegExp(`^$|${pattern}`), (e) => {
          if (errorMessage && errorMessage !== 'keepErrorMessage') return errorMessage;
          return `The given ${e.path} does not match the correct pattern.`;
        }),
      GreaterThanFormValidator: yup
        .string()
        .ensure()
        .test('moreThan', errorMessage, (value) => Number(value) > pattern),
      EmptyOrGreaterThanFormValidator: yup
        .string()
        .ensure()
        .test('moreThan', errorMessage, (value) => isEmpty(value) || Number(value) > pattern),
      LessThanFormValidator: yup
        .string()
        .ensure()
        .test('lessThan', errorMessage, (value) => Number(value) < pattern),
      EmptyOrLessThanFormValidator: yup
        .string()
        .ensure()
        .test('moreThan', errorMessage, (value) => isEmpty(value) || Number(value) < pattern),
      MinResultsLengthFormValidator: yup
        .string()
        .ensure()
        .test('min', errorMessage, (value) => {
          // Checkbox sends all values splitted by ','
          const selectedValues = isEmpty(value) ? 0 : String(value).split(',').length;
          return selectedValues >= pattern;
        }),
      MaxResultsLengthFormValidator: yup
        .string()
        .ensure()
        .test('min', errorMessage, (value) => {
          // Checkbox sends all values splitted by ','
          const selectedValues = String(value).split(',').length;
          return selectedValues <= pattern;
        }),
      IsInFormValidator: yup
        .string()
        .ensure()
        .test('is in', errorMessage, (value) => pattern.split(',').some((key) => key === value)),
      IsInExtendedDelimiterFormValidator: yup
        .string()
        .ensure()
        .test('is in', errorMessage, (value) => pattern.split('#').some((key) => key === value)),
      NotEmptyCheckboxFormValidator: yup
        .string()
        .ensure()
        .test('required', errorMessage, (value) => {
          return !isEmpty(String(value));
        }),
    };

    return validationSchema[validator];
  };

  // split the fs rule to its last word to match it
  const ruleSplitted = (pattern, fsRule, errorMessage) => {
    const splitted = fsRule.split('.');
    return yupValidation(pattern, splitted[splitted.length - 1], errorMessage);
  };

  // create yup mixed to add dynamically rules with concat
  let yupCombined = yup.mixed();

  // add all rules to yup
  rules.forEach((rule) => {
    const matchingRule = ruleSplitted(
      rule.pattern,
      rule.formValidator,
      (rule.errorMessage ?? '') || 'keepErrorMessage',
    );
    yupCombined = yupCombined.concat(matchingRule);
  });

  return {
    name: rules[0].fieldName,
    rule: yupCombined,
  };
}
