// components
import { BUTTON_TYPES, Button } from 'components/Button/Button';
import { InnerHtml } from 'components/InnerHtml/inner-html';

interface UploadedFileInfoProps {
  fileName: string;
  deleteFile: () => void;
}

export default function UploadedFileInfo({
  fileName,
  deleteFile,
}: Readonly<UploadedFileInfoProps>) {
  return (
    <div>
      <InnerHtml as="span" className="form-item__file" content={fileName} />
      <Button symbol="close" type={BUTTON_TYPES.PLAIN} onClick={deleteFile} />
    </div>
  );
}
