interface LoaderProps {
  size?: 'small' | 'medium' | 'large';
  color?: 'black' | 'white';
}

export function Loader({ size = 'medium', color = 'black' }: Readonly<LoaderProps>) {
  return (
    <div className={`c-loader c-loader--${size} c-loader--color-${color}`}>
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  );
}
