import styled from 'styled-components';
import { Typo } from '@geberit/gdds';

export const StyledList = styled.div`
  position: relative;
  z-index: 2;
  width: var(--list-view-width);
  margin-left: var(--list-view-margin-left);
  background-color: ${({ theme }) => theme.colors.white};

  .count {
    font-weight: bold;
  }

  .search-note {
    color: #5c5c5c;
    padding: 1rem;
  }

  @media ${({ theme }) => theme.device.large} {
    max-height: var(--map-height);
    height: var(--map-height);
    width: calc(calc(100% - 1rem) / 3);
    margin-left: 0rem;
    z-index: unset;
  }
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  height: calc(100% - 2.5rem);
`;

export const ListHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;

  p {
    margin: 0;
  }

  @media ${({ theme }) => theme.device.medium} {
    padding-left: 1.5rem;
  }

  @media ${({ theme }) => theme.device.large} {
    padding-left: 0.5rem;
  }
`;

export const StyledListHeader = styled(Typo)`
  && {
    white-space: nowrap;
    margin-right: 1rem;
  }
`;

export const ListBody = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 0 1rem;

  > p {
    margin: 0;
  }

  @media ${({ theme }) => theme.device.medium} {
    padding: 0 1.5rem;
  }

  @media ${({ theme }) => theme.device.large} {
    padding: 0;
  }
`;
