import { Dispatch, SetStateAction, createContext } from 'react';

// types
import { FieldProps } from './field';

type Status = {
  messageIcon?: 'alert' | 'success';
  validationError?: boolean;
};

export type FormContextType = {
  formType: string;
  submitMessages: {
    error: string;
    success: string;
    validate: string;
  };
  status: Status;
  setStatus: (status?: Status) => void;
  preConNames: string[];
  uploadProgress: number;
  files: Record<string, Field[]>;
  filesErrorMessage?: string;
  addFile: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    maxFileCount: number,
  ) => void;
  deleteFile: (name: string, errorMessage: string, index: number) => void;
  preconditions: Preconditions['conditions'];
  hasOxomiButton: boolean;
  hasFileUpload: boolean;
  setFilesErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  captchaField?: FieldProps;
  oxomiButtonClick?: () => void;
};

export const FormContext = createContext({
  formType: '',
  submitMessages: {
    error: '',
    success: '',
    validate: '',
  },
  status: {},
  setStatus: () => {},
  preConNames: [],
  uploadProgress: 0,
  files: {},
  addFile: () => {},
  deleteFile: () => {},
  preconditions: [],
  hasOxomiButton: false,
  hasFileUpload: false,
  setFilesErrorMessage: () => {},
} as FormContextType);
