// need to move this out differently, cause of import cycle
const homepageListSelector = (state: AppState) => state.homepageList;

// Get the current language
export const currentLanguageSelector = (state: AppState): string => {
  const homepage = homepageListSelector(state);

  if (!homepage) return '';

  return (
    (homepage.currentLanguage || homepage.item?.find((i) => i.isMasterLanguage)?.language) ?? ''
  );
};

export default currentLanguageSelector;
