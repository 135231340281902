// types
import type { NoResultProps } from '../types'

// components
import { Title } from 'components/ContentElements/Title/Title';
import { TitleFormats } from 'components/ContentElements/Title/title.types';
import { Translation } from 'components/Translation/Translation';

function NoResult({ query = '', headline = '' }: Readonly<NoResultProps>) {
  return (
    <>
      <Title className="cell" Format={TitleFormats.h1} title={headline} pageHeadline />
      <p className="page-infotext">
        <Translation id="web20_search_no-matches" placeholder={{ query }} />
      </p>
      <h4>
        <Translation id="web20_search_suggestions" />
      </h4>
      <ul>
        <li>
          <Translation id="web20_search_spelled-correctly" />
        </li>
        <li>
          <Translation id="web20_search_different-keywords" />
        </li>
        <li>
          <Translation id="web20_search_general-keywords" />
        </li>
        <li>
          <Translation id="web20_search_less-keywords" />
        </li>
      </ul>
    </>
  );
}

export default NoResult;
