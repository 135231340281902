import { useEffect, createRef, RefObject } from 'react';
import key from 'weak-key';
import { usePathname } from 'next/navigation';

// types
import type { VideoPreviewImageType } from '../ContentElementsGdds/video-channel/types';

// components
import { TextVideo } from '../ContentElements/text-video/text-video';

export type VideoItemLargeProps = {
  video: {
    title: string;
    description: string;
    video: {
      videoId: string;
      videoPreviewImage: VideoPreviewImageType<string>;
    };
  };
};

export default function VideoItemLarge({
  video,
  video: {
    title,
    description,
    video: { videoId, videoPreviewImage },
  },
}: Readonly<VideoItemLargeProps>) {
  const videoItemRef = createRef<HTMLDivElement>();
  const pathname = usePathname();

  useEffect(() => {
    const currentVideoItemRef = videoItemRef.current;
    const hash = pathname.split('#')?.[1];
    if (hash === videoId && currentVideoItemRef) {
      currentVideoItemRef.scrollIntoView();
    }
  }, []);

  return (
    <div
      className="c-video-center__item c-video-center__item--large"
      id={`${videoId}`}
      ref={videoItemRef as RefObject<HTMLDivElement>}
    >
      <TextVideo
        key={key(video)}
        headlinePosition="content"
        {...{ title, text: description, video: { videoId, videoPreviewImage } }}
      />
    </div>
  );
}
