// types
import { IEntry } from '../../locator.types';

// utils
import { isDealerGeberit, isDealerPartner } from '../../utils/dealer-helpers';

export type IWithDistance<T extends IEntry> = T & { distance: number };

export const withDistance =
  <T extends IEntry>(center: ICoordinate) =>
  (e: T): IWithDistance<T> => {
    return {
      ...e,
      distance:
        'distance' in e
          ? (e.distance as number)
          : window.google.maps.geometry.spherical.computeDistanceBetween(center, e),
    };
  };

export const sortByDistance = <T extends IEntry = IEntry>(
  a: IWithDistance<T>,
  b: IWithDistance<T>,
) => {
  return a.distance - b.distance;
};

const sortByPartner = <T extends IEntry = IEntry>(a: IWithDistance<T>, b: IWithDistance<T>) => {
  let sortee = (isDealerPartner(b.partnertype) ? 2 : 1) - (isDealerPartner(a.partnertype) ? 2 : 1);
  if (sortee === 0) {
    sortee = sortByDistance(a, b);
  }

  return sortee;
};

export const sortByGeberit =
  (partnerAware: boolean) =>
  <T extends IEntry = IEntry>(a: IWithDistance<T>, b: IWithDistance<T>) => {
    let sortee =
      (isDealerGeberit(b.partnertype) ? 2 : 1) - (isDealerGeberit(a.partnertype) ? 2 : 1);
    if (sortee === 0) {
      sortee = partnerAware ? sortByPartner(a, b) : sortByDistance(a, b);
    }

    return sortee;
  };
