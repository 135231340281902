import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useFocus } from 'react-aria';
import { Icon, Typo } from '@geberit/gdds';

// components
import { FyloutItem } from './flyout-item';
import { LocationIcon } from './location-icon';
import { RefsContext } from '../map';

// styles
import {
  StyledFlyout,
  StyledForm,
  StyledInput,
  StyledInputWrapper,
  Wrapper,
} from './search.styles';

// types
import { IHighlightedprediction } from '../locator.types';
import { SearchProps } from './search.types';

// utils
import { useOnClickOutside } from 'utils/use-on-click-outside';
import { useKeyboardNavigation } from './utils/use-keyboard-navigation';
import { useIsDesktop } from '../../../App/SizeProvider';

export function Search({
  suggestions = [],
  flyoutLabel,
  placeholder,
  iconName = 'Location',
  onChange,
  onSubmit,
  onLocationClick,
  value,
  geoLocated,
  geoLocationPending,
  ...props
}: Readonly<SearchProps>) {
  const fromRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [showInputFlyout, setShowInputFlyout] = useState(false);
  const isDesktop = useIsDesktop({ gdds: true });
  const { locatorWrapperRef } = useContext(RefsContext);

  const isMobileOverlayMode = isInputFocused && !isDesktop;

  const { focusProps } = useFocus({
    onFocusChange: (focused) => {
      if (!focused) {
        document.body.style.touchAction = 'unset';
        return;
      }

      const isIPhone =
        typeof window !== 'undefined' &&
        (window.navigator.platform === 'iPhone' ||
          window.navigator.userAgent.indexOf('iPhone') !== -1);

      if (isIPhone) {
        setTimeout(() => {
          // Revert automatic scrolling on IOS
          window.scrollTo({ top: 0, left: 0 });
          document.body.scrollTop = 0;

          // Prevents scrolling on underlying content on IOS
          document.body.style.touchAction = 'none';
        }, 150);
      }

      setIsInputFocused(true);
      setShowInputFlyout(true);
    },
  });

  //useBodyScroll(isMobileOverlayMode);

  const { focusedIndex, setFocusedItemIndex, ...keyboardNavigationProps } = useKeyboardNavigation({
    onSelect: () => {
      getOnSelectHandler(suggestions[focusedIndex])();
    },
    itemsCount: suggestions.length,
  });

  const shouldDisplayFlyout = useMemo(
    () => showInputFlyout && suggestions.length > 0,
    [showInputFlyout, suggestions],
  );

  useOnClickOutside(fromRef, () => {
    setShowInputFlyout(false);

    if (isDesktop) {
      setIsInputFocused(false);
    }
  });

  function onChangeHandler(val: string, final?: boolean) {
    onChange(val, final);
  }

  useEffect(() => {
    if (showInputFlyout) setFocusedItemIndex(-1);
  }, [setFocusedItemIndex, showInputFlyout]);

  useEffect(() => {
    if (!locatorWrapperRef) {
      return;
    }

    if (isMobileOverlayMode) {
      locatorWrapperRef.current?.classList.add('fixed');
    } else {
      locatorWrapperRef.current?.classList.remove('fixed');
    }
  }, [isMobileOverlayMode, locatorWrapperRef]);

  const getOnSelectHandler = (item: IHighlightedprediction) => () => {
    const { secondaryText, mainText } = item;

    onChangeHandler(`${mainText} ${secondaryText}`.trimEnd(), true);
    setShowInputFlyout(false);
    setIsInputFocused(false);
  };

  const onSubmitHandler = () => {
    inputRef.current?.blur();
    setShowInputFlyout(false);
    setIsInputFocused(false);
    onSubmit();
  };

  return (
    <Wrapper isFocused={isInputFocused}>
      <StyledForm
        ref={fromRef}
        onSubmit={(event) => {
          event.preventDefault();
          onSubmitHandler();
        }}
      >
        <StyledInputWrapper
          darkIcon={isInputFocused || Boolean(value)}
          isMobileOverlayMode={isMobileOverlayMode}
        >
          {
            <span
              className="leftIcon lens"
              onClick={onSubmitHandler}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onSubmitHandler();
                }
              }}
            >
              <Icon symbol="Search" />
            </span>
          }
          {
            <span
              className="leftIcon backButton"
              onClick={() => {
                setIsInputFocused(false);
                setShowInputFlyout(false);
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setIsInputFocused(false);
                  setShowInputFlyout(false);
                }
              }}
            >
              <Icon symbol={isInputFocused ? 'ArrowLeft' : 'Search'} />
            </span>
          }
          <StyledInput
            autoComplete="off"
            type="text"
            {...props}
            {...focusProps}
            {...keyboardNavigationProps}
            placeholder={isInputFocused ? undefined : placeholder}
            boxShadow={!isInputFocused && !showInputFlyout}
            value={value}
            onChange={(event) => {
              setShowInputFlyout(true);
              onChangeHandler(event.target.value);
            }}
            ref={inputRef}
          />
          {value && isInputFocused ? (
            <span
              className="resetButton"
              onClick={() => {
                onChangeHandler('');
              }}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  onChangeHandler('');
                }
              }}
            >
              <Icon symbol="Close" />
            </span>
          ) : (
            <LocationIcon
              active={geoLocated}
              pending={geoLocationPending}
              onClick={onLocationClick}
            />
          )}
        </StyledInputWrapper>
        {shouldDisplayFlyout && (
          <StyledFlyout>
            <Typo variant="p2" fontWeight={500}>
              {flyoutLabel}
            </Typo>
            {suggestions.map((item, index) => {
              const { hightlightedMainText, secondaryText } = item;

              return (
                <FyloutItem
                  key={item.mainText}
                  focused={focusedIndex === index}
                  iconName={iconName}
                  onSelectOption={getOnSelectHandler(item)}
                  mainText={hightlightedMainText}
                  secondary_text={secondaryText}
                />
              );
            })}
          </StyledFlyout>
        )}
      </StyledForm>
    </Wrapper>
  );
}
