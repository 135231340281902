import { useIsPreview } from './hooks/useIsPreview';

export function usePreviewIdBuilder() {
  const isPreview = useIsPreview();
  return getPreviewIdBuilder(isPreview);
}

export function getPreviewIdBuilder(isPreview: boolean) {
  return (previewId: string | undefined | false) => {
    if (!isPreview || !previewId) {
      return undefined;
    }

    return previewId;
  };
}
