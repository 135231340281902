function hasAtLeastPixelDensity(n) {
  return (
    typeof window !== 'undefined' &&
    window.matchMedia &&
    window.window.matchMedia(retinaQuery(n)).matches
  );
}

function retinaQuery(n) {
  let threshold;
  let thresholdFraction;

  if (n <= 1) {
    threshold = 1;
    thresholdFraction = 1;
  } else {
    // makes it more consistent, so wer're active looking for 1.5 oder 2.5 tresholds
    threshold = n - 0.5;
    thresholdFraction = `${2 * n - 1}/2`;
  }

  return `(-webkit-min-device-pixel-ratio: ${threshold}), (min--moz-device-pixel-ratio: ${threshold}), (-o-min-device-pixel-ratio: ${thresholdFraction}), (min-resolution: ${threshold}dppx)`;
}

export function useRetinaSrc(imageObj?: ImageObject): string | false {
  if (!imageObj) return false;

  if (hasAtLeastPixelDensity(3) && imageObj.url3x) {
    return imageObj.url3x;
  }
  if (hasAtLeastPixelDensity(2) && imageObj.url2x) {
    return imageObj.url2x;
  }

  return imageObj.url;
}
