import { useSelector } from 'react-redux';

import { currentLanguageSelector } from '../../utils/selectors/languageSelectors';

export function useCurrentLanguage() {
  return useSelector(currentLanguageSelector);
}

export function useCurrentLanguageFromLocale() {
  const locale = useSelector(currentLanguageSelector);
  return locale?.substring(0, 2).toLowerCase() || '';
}
