import { format } from 'date-fns';

// utils
import { Log } from 'services/log';

export function validateDate(d: unknown, formatTranslation: string, locale?: Locale) {
  if (d instanceof Date) {
    try {
      if (locale) {
        return format(d, formatTranslation, { locale });
      }

      return format(d, formatTranslation);
    } catch {
      Log.error('Failed to format date', d);
      return null;
    }
  }

  return null;
}
