import { Col, Container, Row } from '@geberit/gdds';

export function getWrapperElement<T extends 'container' | 'row' | 'col'>(
  element: T,
  isGroup: boolean,
):
  | string
  | (T extends 'container' ? typeof Container : never)
  | (T extends 'row' ? typeof Row : never)
  | (T extends 'col' ? typeof Col : never) {
  if (isGroup) {
    return {
      container: Container,
      row: Row,
      col: Col,
    }[element];
  }
  return 'div';
}
