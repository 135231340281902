import { Typo } from '@geberit/gdds';

// styles
import { StyledLabelsWrapper } from './dealer-label.styles';

// types
import type { IEntry } from '../../locator.types';

// utils
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import {
  getDealerCategoryName,
  isDealerGeberit,
  isDealerPartner,
} from '../../utils/dealer-helpers';

interface DealerLabelProps {
  entry: IEntry;
}

export function DealerLabel({ entry }: Readonly<DealerLabelProps>) {
  const locatorConfig = useLocatorConfiguration();
  const isPartner = isDealerPartner(entry.partnertype);
  const isGeberit = isDealerGeberit(entry.partnertype);
  const category = getDealerCategoryName(entry, locatorConfig);

  return (
    <StyledLabelsWrapper>
      {isPartner && locatorConfig?.filter?.showPartnerSwitch && (
        <Typo variant="label1" fontWeight={500}>
          {locatorConfig?.labels?.partner}
        </Typo>
      )}
      {isGeberit && (
        <Typo variant="label1" fontWeight={500}>
          {locatorConfig?.labels?.brand}
        </Typo>
      )}
      <Typo variant="label1">{category}</Typo>
    </StyledLabelsWrapper>
  );
}
