export const CUSTOM_MAP_STYLES = [
  { featureType: 'landscape.man_made', stylers: [{ visibility: 'off' }] },
  {
    elementType: 'geometry',
    featureType: 'landscape.man_made',
    stylers: [{ lightness: 60 }, { saturation: -100 }, { visibility: 'on' }],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'landscape.man_made',
    stylers: [
      { color: '#f5f4f5' },
      { zoom: 16 },
      { color: '#fdfdfd' },
      { zoom: 17 },
      { color: '#f5f4f5' },
    ],
  },
  {
    elementType: 'geometry.fill',
    featureType: 'landscape.man_made.building',
    stylers: [{ color: '#ffffff' }],
  },
  {
    elementType: 'geometry.stroke',
    featureType: 'landscape.man_made.building',
    stylers: [{ visibility: 'off' }],
  },
  { featureType: 'landscape.natural', stylers: [{ color: '#ffffff' }] },
  {
    elementType: 'labels.text.fill',
    featureType: 'landscape.natural',
    stylers: [{ visibility: 'off' }],
  },
  {
    elementType: 'labels.text.stroke',
    featureType: 'landscape.natural',
    stylers: [{ visibility: 'off' }],
  },
  { featureType: 'poi.attraction', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.business', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.government', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.medical', stylers: [{ visibility: 'off' }] },
  { elementType: 'labels', featureType: 'poi.park', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.place_of_worship', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.school', stylers: [{ visibility: 'off' }] },
  {
    elementType: 'geometry.fill',
    featureType: 'poi.sports_complex',
    stylers: [{ visibility: 'on' }],
  },
  { elementType: 'labels', featureType: 'poi.sports_complex', stylers: [{ visibility: 'off' }] },
  { elementType: 'geometry.fill', featureType: 'road', stylers: [{ color: '#e8ebf1' }] },
  { elementType: 'geometry.stroke', featureType: 'road', stylers: [{ visibility: 'off' }] },
  { elementType: 'labels', featureType: 'road', stylers: [{ visibility: 'off' }] },
  { featureType: 'transit', stylers: [{ visibility: 'off' }] },
  { featureType: 'transit.station', stylers: [{ visibility: 'off' }] },
  { elementType: 'labels', featureType: 'transit.station', stylers: [{ visibility: 'off' }] },
];
