// components
import { EditWrapper } from '../ContentCreator/EditWrapper';

interface WrapperProps {
  isContainerChild?: boolean;
  previewId?: string;
}

export function Wrapper({
  children,
  isContainerChild,
  previewId,
  ...props
}: Readonly<React.PropsWithChildren<WrapperProps>>) {
  return isContainerChild ? (
    children
  ) : (
    <EditWrapper {...props} previewId={previewId}>
      {children}
    </EditWrapper>
  );
}
