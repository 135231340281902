import { useState, useEffect } from 'react';

export function useHash() {
  const [hash, setHash] = useState<string | undefined>();

  useEffect(() => {
    handleHashChange();

    function handleHashChange() {
      setHash(window.location.hash.split('#').at(1));
    }

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return hash;
}
