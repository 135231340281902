// styles
import styles from './addressBox.module.scss';

// types
import type { AdressBoxProps } from './AddressBox.types';

// utils
import { useSelector } from 'react-redux';
import { translationKeysSelector } from 'utils/selectors/translationSelectors';

export function AddressBox({
  title,
  address,
  salutation,
  country,
  isDelivery = false,
}: Readonly<AdressBoxProps>) {
  const translations = useSelector(translationKeysSelector);

  function ListEntryWithLabel(props: { label?: string; value?: string }) {
    return (
      <li className={styles.listEntryWithLabel}>
        <span className={styles.listEntryWithLabel__label}>{props.label}</span>
        <span>{props.value || '-'}</span>
      </li>
    );
  }

  return (
    <div className="addressBox">
      <h4 className={styles.bottomMargin}>{title}</h4>
      <ul className={`${styles.addressBoxWrapper} ${styles.bottomMargin}`}>
        <li>
          {address.salutation && salutation} {address.firstName} {address.lastName}
        </li>

        {address.company && <li>{address.company}</li>}

        <li>
          {address.street} {address.street2}
        </li>
        <li>
          {address.zip} {address.city}
        </li>

        <li>{country}</li>

        {!isDelivery && (
          <>
            <ListEntryWithLabel
              label={translations?.web20_checkout_address_email_label}
              value={address.email}
            />

            <ListEntryWithLabel
              label={translations?.web20_checkout_address_phone_number_label}
              value={address.phone}
            />

            <ListEntryWithLabel
              label={translations?.web20_checkout_address_mobile_phone_number_label}
              value={address.mobile}
            />
          </>
        )}

        {!isDelivery && address.sapCustomerNumber && <li>ID {address.sapCustomerNumber}</li>}
      </ul>
    </div>
  );
}
