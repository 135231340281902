import React, { forwardRef, useEffect, useState } from 'react';
import { Button, Typo } from '@geberit/gdds';

// styles
import { StyledDealerPopup } from './dealer-popup.styles';

// types
import { IEntry, ILocation, Point } from '../../locator.types';
import { IWithDistance } from '../utils/pins-manager';

// utils
import { DistanceAndDuration } from '../distance-and-duration';
import { DealerLabel } from '../dealer-label';
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { planRoute } from '../utils/plan-route';
import { decodingContent } from 'utils/decodingContent';
import { useCountryByIsoCode } from 'utils/hooks/use-country-by-isocode';
import { useGoToDealerPage } from '../../detail-page/use-goto-dealer-page';
import { useIsDesktop } from 'components/App/SizeProvider';

interface DealerPopUpProps {
  dealer: IWithDistance<IEntry> | null;
  location: ILocation | undefined;
  onClose?: () => void;
  point?: Point;
  closeable?: boolean;
}

export const DealerPopup = forwardRef<HTMLDivElement, DealerPopUpProps>(
  ({ dealer, location, onClose, point, closeable }, ref) => {
    // we use the dealerClone for the mobile animation when the popup gets hidden
    const [dealerClone, setDealerClone] = useState<IWithDistance<IEntry> | null>(dealer);
    const [popupWidth, setPopupWidth] = useState(0);
    const locatorConfig = useLocatorConfiguration();
    const destination = `${dealerClone?.name} ${dealerClone?.address} ${dealerClone?.zip} ${dealerClone?.city}`;
    const toDealerPage = useGoToDealerPage();
    const isDesktop = useIsDesktop();
    const getCountryByCode = useCountryByIsoCode();

    useEffect(() => {
      if (dealerClone && ref && typeof ref !== 'function' && ref.current) {
        setPopupWidth(ref.current.getBoundingClientRect().width);
      }
    }, [ref, dealerClone]);

    useEffect(() => {
      if (dealer) {
        setDealerClone(dealer);
      }
    }, [dealer]);

    function navigateToDealerPage() {
      if (!dealerClone) {
        return;
      }
      toDealerPage(dealerClone);
    }

    return (
      <StyledDealerPopup ref={ref} point={point} show={Boolean(dealer)} popupWidth={popupWidth}>
        {dealerClone && (
          <>
            {closeable && (
              <Button
                className="close-button"
                isIcon
                stylingType="icon"
                symbol="close"
                onClick={onClose}
              />
            )}
            <DistanceAndDuration location={location} entry={dealerClone} showDuration />
            <DealerLabel entry={dealerClone} />
            <div className="address">
              <Typo variant="p1" fontWeight={700}>
                {dealerClone.name}
              </Typo>
              <Typo variant="p2">{`${dealerClone.address},`}</Typo>
              <Typo variant="p2">{`${dealerClone.zip} ${dealerClone.city}`}</Typo>
              <Typo variant="p2">{getCountryByCode(dealerClone.country)}</Typo>
            </div>
            <div className="opening-hours" />
            <div className="buttons">
              <Button
                onClick={navigateToDealerPage}
                stylingType={isDesktop ? 'flatHighlight' : 'primary'}
                symbol={isDesktop ? 'Location' : undefined}
              >
                {decodingContent(locatorConfig.map?.moreDetails)}
              </Button>
              <Button
                onClick={() => planRoute(destination)}
                stylingType={isDesktop ? 'flatHighlight' : 'secondary'}
                symbol={isDesktop ? 'Send' : undefined}
              >
                {decodingContent(locatorConfig.map?.routePlanner)}
              </Button>
            </div>
          </>
        )}
      </StyledDealerPopup>
    );
  },
);
