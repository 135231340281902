import React from 'react';
import { Button } from '@geberit/gdds';

// styles
import { StyledContainer, StyledIconWrapper } from './primary-filter-view.styles';

// icons
import { ShowroomIcon } from './icons/showroom_icon';
import { PlumberIcon } from './icons/plumber_icon';

// utils
import { PrimaryFilter, usePrimaryFilter } from './primary-filter-view.hook';

export function PrimaryFilterView() {
  const [{ active, label, selected }, setPrimaryFilter] = usePrimaryFilter();
  const onSelectFilter = (value: PrimaryFilter | undefined) => () => setPrimaryFilter(value);

  return (
    <StyledContainer>
      {active.length > 1 && (
        <Button stylingType="flat" selected={selected === 'all'} onClick={onSelectFilter('all')}>
          {label.all}
        </Button>
      )}
      {active.includes('s') && (
        <Button
          className={active.length === 1 || selected === 's' ? 'selected' : undefined}
          stylingType="flat"
          onClick={onSelectFilter('s')}
          selected={active.length === 1 || selected === 's'}
        >
          <StyledIconWrapper>
            <ShowroomIcon />
          </StyledIconWrapper>
          {label.showroom}
        </Button>
      )}
      {active.includes('i') && (
        <Button
          className={active.length === 1 || selected === 'i' ? 'selected' : undefined}
          stylingType="flat"
          onClick={onSelectFilter('i')}
          selected={active.length === 1 || selected === 'i'}
        >
          <StyledIconWrapper>
            <PlumberIcon />
          </StyledIconWrapper>
          {label.plumber}
        </Button>
      )}
    </StyledContainer>
  );
}
