import styled, { css } from 'styled-components';

export const SliderWrapper = styled.div(
  ({ hasBottomPadding }: { hasBottomPadding: boolean }) =>
    hasBottomPadding &&
    css`
      padding-bottom: 5.625rem;
    `,
);

export const SliderVideoPlayerContainer = styled.div(
  ({ isHeroElement }: { isHeroElement: boolean }) => css`
    overflow: hidden;
    max-height: ${isHeroElement ? '37.5vw' : '29.1666666667vw'};
    display: flex;
    min-height: 250px;
    align-items: center;
  `,
);
