// styles
import { StyledContainer, StyledTitle } from './filter.styles';

// components
import { PrimaryFilterView } from './primary-filter-view';
import { SecondaryFilterView } from './secondary-filter-view';
import { MoreFilter } from './more-filter-view';
import { ControlButtons } from './control-buttons';

// utils
import { useLocatorConfiguration } from '../utils/use-locator-configuration';

interface FilterProps {
  visible: boolean;
}

export function Filter({ visible }: Readonly<FilterProps>) {
  const config = useLocatorConfiguration();

  return (
    <StyledContainer visible={visible}>
      {config.filter?.allFilters && <StyledTitle>{config.filter?.allFilters}</StyledTitle>}
      {config.filter?.filterVisible && <PrimaryFilterView />}
      <SecondaryFilterView />
      <MoreFilter />
      <ControlButtons />
    </StyledContainer>
  );
}
