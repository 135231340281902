enum LogLevel {
  Debug = 'DEBUG',
  Info = 'INFO',
  Warn = 'WARN',
  Error = 'ERROR',
}

const defaultLogLevel = LogLevel.Warn;

const _levelOrder = Object.values(LogLevel);

function getLogLevel() {
  const envLogLevel = process.env.LOGLEVEL_FRONTEND?.toUpperCase();
  const windowLogLevel = typeof window !== 'undefined' && window?._logLevel;

  if (windowLogLevel) return windowLogLevel;
  if (envLogLevel) return envLogLevel;

  return defaultLogLevel;
}

const logger = (level: LogLevel) => {
  if (!console['_log']) {
    console.log('LOGLEVEL_FRONTEND', getLogLevel());
    console['_log'] = console.log;
    console.log = function () {};
  }
  return (...data: unknown[]) => {
    const logLevel = getLogLevel();
    const now = new Date().toLocaleString('en', { hour12: false });
    const log = console['_log'] || console.log;
    if (_levelOrder.indexOf(level) >= _levelOrder.indexOf(logLevel as LogLevel)) {
      log(level, now, ...data);
    }
  };
};

export const Log = {
  debug: logger(LogLevel.Debug),
  info: logger(LogLevel.Info),
  warn: logger(LogLevel.Warn),
  error: logger(LogLevel.Error),
};
