import styled from 'styled-components';

export const StyledPartnerSwitch = styled.div<{ reverse: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  justify-content: flex-end;

  ${({ reverse }) =>
    reverse
      ? `
        flex-direction: row-reverse;
        transform: translate(-0.5rem);
        `
      : ''}

  & > span {
    cursor: pointer;
  }

  & p {
    margin: 0;
  }

  &&& label {
    span {
      top: 0.625rem;
      left: 0.5rem;

      :after,
      :before {
        box-sizing: unset;
      }

      :before {
        background-color: transparent;
      }
    }

    :hover {
      span {
        ::before {
          background-color: ${({ theme }) => `rgba(${theme.colors.transparentBlack}, 0.08)`};
        }
      }
    }
  }
`;
