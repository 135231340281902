import { useState } from 'react';
import { KeyBoardKeys } from '@geberit/gdds';

export const useKeyboardNavigation = ({
  onSelect,
  itemsCount,
}: {
  onSelect: () => void;
  itemsCount: number;
}) => {
  const [focusedItemIndex, setFocusedItemIndex] = useState(-1);

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === KeyBoardKeys.ArrowUp) {
      event.preventDefault();
      setFocusedItemIndex((i) => {
        if (focusedItemIndex > 0) {
          return i - 1;
        }

        return itemsCount - 1;
      });
    }

    if (event.key === KeyBoardKeys.ArrowDown) {
      event.preventDefault();
      setFocusedItemIndex((i) => {
        if (focusedItemIndex < itemsCount - 1) {
          return i + 1;
        }

        return 0;
      });
    }

    if (event.key === KeyBoardKeys.Enter && focusedItemIndex >= 0) {
      onSelect();
    }
  };

  return {
    onKeyDown: handleOnKeyDown,
    focusedIndex: focusedItemIndex,
    setFocusedItemIndex,
  };
};
