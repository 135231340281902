'use client';

import { useEffect } from 'react';
import { useSearchParams } from 'next/navigation';

// styles
import { IntroTextStyles } from './press-portal.styles';

// components
import Footer from 'components/Footer/Footer';
import PressPortal from 'components/ContentElementsGdds/PressPortal/press-portal';
import PressPortalDetailPage from 'components/ContentElementsGdds/PressPortal/press-portal-detail-page';
import PressPortalAssetDetailPage from 'components/ContentElementsGdds/PressPortal/press-portal-asset-detail-page';
import Breadcrumb from 'components/Breadcrumb/Breadcrumb';
import PressPortalTagOverviewPage from 'components/ContentElementsGdds/PressPortal/press-portal-tag-overview-page';
import { useGdds } from 'utils/hooks/use-gdds';

// utils
import { contentExists, useContent } from 'components/Page/page-hooks';

export function PressPortalScene() {
  let pageType: 'overview' | 'detail_page' | 'asset_detail_page' | 'tag_overview_page' = 'overview';
  let tag: string | undefined;
  const params = useSearchParams();
  const isGdds = useGdds();

  if (params.has('page')) {
    switch (params.get('page')) {
      case 'detailpage':
        pageType = 'detail_page';
        break;
      case 'asset-detailpage':
        pageType = 'asset_detail_page';
        break;
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pageType]);

  const content = useContent();

  if (!contentExists(content)) {
    return null;
  }

  const {
    breadcrumbs,
    metaData,
    page: { intro, text, headline },
  } = content;

  if (params.has('tag')) {
    pageType = 'tag_overview_page';
    tag = params.get('tag') ?? undefined;
  }

  return (
    <>
      <main>
        {pageType === 'overview' && (
          <>
            <Breadcrumb items={breadcrumbs} />
            {(headline || intro || text) && (
              <IntroTextStyles
                title={headline}
                text={text}
                intro={intro}
                border="disable-border"
                isGdds={isGdds}
                emphasize={!isGdds ? 'title' : undefined}
                disableInEdit
              />
            )}
            <PressPortal isGdds={isGdds} />
          </>
        )}
        {pageType === 'tag_overview_page' && (
          <PressPortalTagOverviewPage tag={tag} breadcrumbs={breadcrumbs} isGdds={isGdds} />
        )}
        {pageType === 'detail_page' && (
          <PressPortalDetailPage metaData={metaData} breadcrumbs={breadcrumbs} isGdds={isGdds} />
        )}
        {pageType === 'asset_detail_page' && (
          <PressPortalAssetDetailPage breadcrumbs={breadcrumbs} />
        )}
      </main>
      <Footer />
    </>
  );
}
