import slugify from 'slugify';
import key from 'weak-key';

export const generateSlug = (phrase?: string, prefix?: string) => {
  if (!phrase || phrase.length === 0) return key({ phrase });

  // TODO: [a-z0-9] und bindestrich
  let slug = slugify(`${phrase}`, {
    remove: /[*+~.,;#()'"!:`/–@?°]/g,
    lower: true,
  });

  // eslint-disable-next-line no-control-regex
  slug = slug.replace(/[^\x00-\x7F]/g, '');

  return prefix ? `${prefix}-${slug}` : slug;
};

export const getStringSpecificId = (name: string, id: string, generatedId?: string) =>
  `${name}-${id}${generatedId ? `-${generatedId}` : ''}`;
