import { Container, Row, Col } from '@geberit/gdds';

// styles
import styles from './hero-image.module.scss';

// types
import type { HeroImageCProps } from './hero-image.types';

// components
import { Image } from 'components/image/image';
import { MiVideoContainer } from 'components/Video/miVideo/MiVideoContainer';
import ConfigurableYoutube from 'components/Video/youtube/configurable-youtube';

// utils
import { useIsMobile } from 'components/App/SizeProvider';
import { buildSize, gridSizes } from 'utils/gridSize';
import { useNord } from 'utils/hooks/use-nord';

function HeroImageC({
  imageObject,
  imageObjectMobile,
  pictureAlt,
  video,
}: Readonly<HeroImageCProps>) {
  const isMobile = useIsMobile();
  const img = isMobile && imageObjectMobile?.url ? imageObjectMobile : imageObject;
  const isNord = useNord();

  const mediaContent = img?.url ? (
    <Image data={img} alt={pictureAlt} title={pictureAlt} />
  ) : (
    <>
      {video?.type === 'youtube' ? (
        <ConfigurableYoutube
          videoId={video.videoId}
          playerControl={video.playerControl}
          language={video.language}
          showLink={video.showLink}
        />
      ) : (
        <MiVideoContainer content={{ video, autoplay: 'no' }} className="hero-image-c" />
      )}
    </>
  );

  if (isMobile) {
    return <div className={styles.heroImageC}>{mediaContent}</div>;
  }

  return (
    <Container
      maxContentWidth={buildSize(isNord ? gridSizes.gddsFullGrid : gridSizes.full)}
      className={styles.heroImageC}
    >
      <Row>
        <Col>{mediaContent}</Col>
      </Row>
    </Container>
  );
}

export default HeroImageC;
