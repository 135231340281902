import { useContext, useMemo } from 'react';

// utils
import { FilterContext } from '../map';
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { useIsDesktop } from 'components/App/SizeProvider';
import { useSearchParamsState } from 'utils/hooks/use-search-params-state';
import { decodingContent } from 'utils/decodingContent';
import { useCountryByIsoCode } from 'utils/hooks/use-country-by-isocode';

export type ISecondaryFilter = 'categories' | 'series' | 'countries';

interface ISecondaryFilterOption {
  id: string;
  text: string;
  value: string;
}

type IUseSecondaryFilter = [
  {
    label: Record<ISecondaryFilter | 'all', string>;
    items: Record<ISecondaryFilter, ISecondaryFilterOption[] | undefined>;
    selected: Record<ISecondaryFilter, string>;
  },
  (key: ISecondaryFilter, value: ISecondaryFilterOption) => void,
];

export interface IFilterOption {
  value: string;
  text: string;
  id: string;
  disabled?: boolean;
}

const valueToOptionMapper = (value: string): IFilterOption => ({
  value: value,
  text: value,
  id: value,
});

export function useSecondaryFilter(): IUseSecondaryFilter {
  const isDesktop = useIsDesktop({ gdds: true });
  const [state, setSearchParamsState] = useSearchParamsState(['pro_cat', 'pro_ser', 'country']);
  const { entries, sortStructure, countries } = useContext(FilterContext);
  const locatorTranslations = useLocatorConfiguration();
  const getCountryByCode = useCountryByIsoCode();

  const productsMap = useMemo(() => {
    const products = entries.map((entry) => entry.products).flat();

    return products.reduce((acc: Record<string, Set<string>>, entry) => {
      if (!acc[entry.category]) {
        acc[entry.category] = new Set<string>();
      }
      entry.series.forEach(acc[entry.category].add, acc[entry.category]);

      return acc;
    }, {});
  }, [entries]);

  const items = useMemo(() => {
    const categories = (
      state.pro_ser !== undefined
        ? Object.entries(productsMap)
            .filter(([_, series]) => series.has(state.pro_ser as string))
            .map(([key]) => key)
        : Object.keys(productsMap)
    )
      .sort((a, b) => sortStructure.indexOf(a) - sortStructure.indexOf(b))
      .map(valueToOptionMapper);

    const series = Array.from(
      state.pro_cat !== undefined
        ? productsMap[state.pro_cat]
        : Object.values(productsMap).reduce((acc, value) => {
            value.forEach(acc.add, acc);
            return acc;
          }, new Set<string>()),
    )
      .sort()
      .map(valueToOptionMapper);

    const allCountries = countries.map((entry) => ({
      value: entry,
      text: getCountryByCode(entry) as string,
      id: entry,
      disabled: false,
    }));
    allCountries.unshift({
      value: 'all',
      text: decodingContent(locatorTranslations?.filter?.filter1Text ?? ''),
      id: 'all_countries',
      disabled: state.country === undefined,
    });

    categories.unshift({
      value: 'all',
      text: decodingContent(locatorTranslations?.filter?.filter1Text ?? ''),
      id: 'all_categories',
      disabled: state.pro_cat === undefined,
    });
    series.unshift({
      value: 'all',
      text: decodingContent(locatorTranslations?.filter?.filter1Text ?? ''),
      id: 'all_series',
      disabled: state.pro_ser === undefined,
    });

    return {
      categories,
      series,
      countries: allCountries.length > 2 ? allCountries : undefined,
    };
  }, [
    state.pro_ser,
    state.pro_cat,
    state.country,
    productsMap,
    countries,
    locatorTranslations?.filter?.filter1Text,
    sortStructure,
    getCountryByCode,
  ]);

  return [
    {
      label: {
        all: decodingContent(locatorTranslations?.filter?.filter1Text ?? ''),
        categories: decodingContent(locatorTranslations?.filter?.dropCategoryText ?? ''),
        series: decodingContent(locatorTranslations?.filter?.dropSeriesText ?? ''),
        countries: decodingContent(locatorTranslations?.filter?.dropCountryText ?? ''),
      },
      items,
      selected: {
        categories: (isDesktop ? state.pro_cat : state.pro_cat ?? 'all') ?? '',
        series: (isDesktop ? state.pro_ser : state.pro_ser ?? 'all') ?? '',
        countries: (isDesktop ? state.country : state.country ?? 'all') ?? '',
      },
    },
    (key, option) => {
      switch (key) {
        case 'categories':
          setSearchParamsState({
            pro_cat:
              state.pro_cat === option.value || option.value === 'all' ? undefined : option.value,
          });
          break;
        case 'series':
          setSearchParamsState({
            pro_ser:
              state.pro_ser === option.value || option.value === 'all' ? undefined : option.value,
          });
          break;
        case 'countries':
          setSearchParamsState({
            country:
              state.country === option.value || option.value === 'all' ? undefined : option.value,
          });
          break;
      }
    },
  ];
}
