import React from 'react';
import styled from 'styled-components';

// components
import { PartnerSwitch } from '../map';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';

const MoreFilterContainer = styled.div`
  grid-area: more;
`;

export function MoreFilter() {
  const isDesktop = useIsDesktop({ gdds: true });

  return (
    <MoreFilterContainer>
      {!isDesktop && <PartnerSwitch reverse></PartnerSwitch>}
    </MoreFilterContainer>
  );
}
