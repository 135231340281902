import { Field } from 'formik';

// styles
import styles from './formikInputFieldStyles.module.scss';

const FormikInputField = <FormValues extends Record<string, unknown>>({
  type = 'text',
  name = '',
  id = '',
  placeholder = '',
  margin = 0,
  error = '',
  isDisabled = false,
  currentValue = '',
  inputBlur = () => {},
  maxLength,
}: Readonly<{
  type?: string;
  name: keyof FormValues;
  id?: string;
  placeholder?: string;
  margin?: string | number;
  error?: boolean | string;
  isDisabled?: boolean;
  currentValue?: string;
  inputBlur?: () => void;
  maxLength?: number;
  touched?: boolean;
}>) => (
  <div className={styles.formikFieldWrapper} style={{ margin }}>
    <Field
      type={type}
      name={name}
      id={id}
      className={error ? styles.inputError : undefined}
      disabled={isDisabled}
      onBlur={() => (inputBlur ? inputBlur() : '')}
      maxLength={maxLength}
    />
    <span
      className={`${styles.inputPlaceholder} ${
        currentValue.length ? styles.inputPlaceholderFilled : ''
      }`}
    >
      {placeholder}
    </span>
    {error && <div className={`Error ${styles.error}`}>{error}</div>}
  </div>
);

export default FormikInputField;
