import { currentLanguageSelector } from './languageSelectors';

const formSelector = (state: AppState) => {
  const language = currentLanguageSelector(state);

  if (!language && !state.form) return null;
  return state.form[language] || null;
};

export const preConDropdownSelector = (state: AppState) => {
  const form = formSelector(state);

  if (!form) return null;
  if (!form.preConDropdown) return null;

  return form.preConDropdown.products || null;
};
