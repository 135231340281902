import styled from 'styled-components';

export const StyledContainer = styled.div<{ visible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99;
  padding: 3rem 1rem 0.625rem 1rem;
  display: ${({ visible }) => (visible ? 'grid' : 'none')};
  grid-template-rows: auto auto auto 1fr;
  grid-gap: 1.5rem;
  grid-template-areas:
    'title'
    'primary'
    'secondary'
    'more'
    'buttons';

  @media ${({ theme }) => theme.device.medium} {
    padding: 3rem 1.5rem 0.75rem 1.5rem;
  }

  @media ${({ theme }) => theme.device.large} {
    position: relative;
    background-color: #f7f7f7;
    padding: 0.75rem 1.25rem;
    grid-template-areas: 'primary secondary more';
    grid-template-columns: auto 1fr auto;
    grid-template-rows: auto;
    margin-bottom: 1rem;
    z-index: 4;
  }
`;

export const StyledTitle = styled.h6`
  grid-area: title;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  text-transform: none;
  margin: 0;

  @media ${({ theme }) => theme.device.large} {
    display: none;
  }
`;
