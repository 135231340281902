import { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { postSimulatedOrderAction } from 'components/Checkout/actions';
import { SessionContext } from 'components/Session/SessionContext';
import { currentCatalogSelector } from '../selectors/productCatalogSelectors';
import { simulatedOrderDataSelector } from 'components/Checkout/selectors';
import {
  getPersistedCheckoutVoucher,
  resetPersistedCheckoutVoucher,
  setPersistedCheckoutVoucher,
} from '../../services/voucher';
import { SimulatedOrderData } from 'components/Checkout/action.types';

export type Voucher = ReturnType<typeof useVoucher>;

export const useVoucher = (invoiceAddress, deliveryAddress) => {
  const persistedVoucherCode = getPersistedCheckoutVoucher()?.order?.voucherCode || null;
  const [appliedVoucher, setAppliedVoucher] = useState<string | null>(persistedVoucherCode);

  const [voucherCode, setVoucherCode] = useState<string>('');
  const [voucherInfo, setVoucherInfo] = useState<string | null>(null);
  const [voucherErrorMessage, setVoucherErrorMessage] = useState<string | null>(null);
  const translate = useTranslationFunction();
  const dispatch: Dispatch<any> = useDispatch();
  const locale = useSelector(currentCatalogSelector);
  const simulatedOrder = useSelector(simulatedOrderDataSelector);

  const {
    state: { session, ciam },
  } = useContext(SessionContext);

  const ciamId = ciam?.UID;
  const sessionId = session;
  const isLogged = Boolean(ciamId && ciamId !== '');
  const id = isLogged ? ciamId : sessionId;

  const handleVoucherInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 10);
    setVoucherCode(newValue);
  };

  const order = { voucherCode: voucherCode };

  const validateVoucher = (orderData: SimulatedOrderData): void => {
    const getVoucherMessage = () => {
      if (!orderData.voucherValid) {
        return { error: translate('web20_checkout_voucher_invalid') };
      }

      if (Number(orderData.priceVoucher) === 0) {
        return { info: translate('web20_checkout_voucher_valid_but_no_effect') };
      }

      return null;
    };

    const voucherErrorMessage = getVoucherMessage();
    if (voucherErrorMessage?.error) {
      setVoucherErrorMessage(voucherErrorMessage.error);
    } else if (voucherErrorMessage?.info) {
      setVoucherInfo(voucherErrorMessage?.info);
    } else {
      setVoucherErrorMessage(null);
      setVoucherInfo(null);
    }

    if (voucherErrorMessage) {
      resetPersistedCheckoutVoucher();
      setAppliedVoucher(null);
      return;
    }

    // Success, voucher is valid!
    setPersistedCheckoutVoucher(order);
  };

  const applyVoucher = () => {
    if (!voucherCode.length) return;

    if (appliedVoucher) {
      // A voucher is already applied
      setVoucherErrorMessage(translate('web20_checkout_voucher_max_reached'));
      return;
    }

    setAppliedVoucher(voucherCode);

    dispatch(
      postSimulatedOrderAction(
        locale,
        id ?? '',
        {
          invoiceAddress,
          deliveryAddress,
          order,
        },
        isLogged,
        (orderData) => validateVoucher(orderData),
      ),
    );
  };

  const removeVoucher = () => {
    resetPersistedCheckoutVoucher();
    setAppliedVoucher(null);
    setVoucherCode('');

    dispatch(
      postSimulatedOrderAction(
        locale,
        id ?? '',
        {
          invoiceAddress,
          deliveryAddress,
        },
        isLogged,
        () => {},
      ),
    );
  };

  const areItemsDiscounted: boolean = Number(simulatedOrder?.priceVoucher || 0) !== 0;

  return {
    voucherCode,
    setVoucherCode,
    appliedVoucher,
    voucherInfo,
    voucherErrorMessage,
    handleVoucherInputChange,
    applyVoucher,
    removeVoucher,
    setVoucherInfo,
    areItemsDiscounted,
  };
};
