import { getStringSpecificId } from 'utils/slug';

type YoutubeVideoProps = {
  id: string;
};

function YoutubeVideo({ id }: Readonly<YoutubeVideoProps>) {
  return (
    <div id={getStringSpecificId('youtube', id)} className="c-youtube-video">
      <iframe title="unique" src={`https://www.youtube.com/embed/${id}`} />
    </div>
  );
}

export default YoutubeVideo;
