import key from 'weak-key';

// types
import type { CartDownloadsProps } from '../../types';

// components
import { BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';
import { InnerHtml } from 'components/InnerHtml/inner-html';

// utils
import { decodingContent } from 'utils/decodingContent';
import { isEmpty } from 'utils/is-empty';

export default function CartDownloads(props: Readonly<CartDownloadsProps>){
  const {
    downloads,
    updateDownload,
    downloadZipHandler,
    downloadProgress,
  } = props;
  const tableData = downloads.map((download) => {
    const {
      meta: { indexer_filetype: downloadDoctype },
      title,
    } = download;
    const doctype = downloadDoctype?.[0];

    return (
      <tr key={key(download)}>
        <td>
          {!isEmpty(title) && <InnerHtml as="span" content={decodingContent(title)} />}
        </td>
        <td>{String(decodingContent(doctype)).toUpperCase()}</td>
        <td>
          <Button
            type={BUTTON_TYPES.SEAMLESS}
            symbol="trashcan"
            color={BUTTON_COLORS.PRIMARY}
            onClick={() => updateDownload(download)}
          />
        </td>
      </tr>
    );
  });

  const downloadCompleted = downloadProgress === 100;
  const downloadIconAnimation = downloadProgress > 0 && !downloadCompleted ? 'download-icon-animation' : '';
  const checkIconClass = downloadCompleted ? 'download-icon-finished' : '';

  let buttonLabel = downloadCompleted
    ? 'web20_downloadcenter_cartdownload_as_zip_completed'
    : 'web20_downloadcenter_cartdownload_as_zip';

  if (downloadProgress === 0.1) {
    buttonLabel = 'web20_downloadcenter_cartdownload_as_zip_creating_zip';
  }

  return (
    <div className="c-cart-downloads">
      <table>
        <tbody>{tableData}</tbody>
        <thead>
          <tr>
            <th>
              <Translation id="web20_downloadcenter_cartmodal_cell_designation" />
            </th>
            <th>
              <Translation id="web20_downloadcenter_cartmodal_cell_format" />
            </th>
            <th />
          </tr>
        </thead>
      </table>
      <Button
        color={BUTTON_COLORS.WHITE}
        background={BUTTON_BACKGROUND.PRIMARY}
        onClick={downloadZipHandler}
        className={`${downloadIconAnimation} ${checkIconClass}`}
      >
        <div className="icon-download__wrapper">
          <span className="icon-download__icon" />
        </div>
        <span className="progress-bar" style={{ width: `${downloadProgress}%` }}></span>
        <span className="download-button--text">
          <Translation id={buttonLabel} />
        </span>
      </Button>
    </div>
  );
}
