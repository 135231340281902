import { useState } from 'react';
import { Button as GDDSButton } from '@geberit/gdds';

// components
import { BUTTON_ALIGNMENT, BUTTON_BACKGROUND, BUTTON_COLORS, Button } from '../Button/Button';

// styles
import styles from './copy-to-clipboard.module.scss';

// utils
import { copyToClipboard } from 'utils/clipboard';
import { useTranslationFunction } from 'utils/hooks/use-translations';
import { useGdds } from 'utils/hooks/use-gdds';
import { classNameBuilder } from 'utils/classNameBuilder';

export function CopyToClipboard({ text }: Readonly<{ text?: string }>) {
  const translate = useTranslationFunction();
  const [copied, setCopied] = useState<boolean | undefined>();
  const isGdds = useGdds();

  function handleOnClick() {
    if (copied) return;

    if (!text) {
      setCopied(false);
      return;
    }

    copyToClipboard(text)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(undefined);
        }, 2000);
      })
      .catch(() => {
        setCopied(false);
      });
  }

  const buttonText = translate(copied ? 'web20_copied' : 'web20_copy');
  const className = copied && 'success';

  return (
    <div className={styles.wrapper}>
      <p title={text}>{text}</p>
      {isGdds ? (
        <GDDSButton
          className={classNameBuilder(styles.nord, className && styles[className])}
          onClick={handleOnClick}
          stylingType="primary"
          symbol={copied ? 'checkmark' : undefined}
        >
          {buttonText}
        </GDDSButton>
      ) : (
        <Button
          color={BUTTON_COLORS.WHITE}
          background={BUTTON_BACKGROUND.PRIMARY}
          align={BUTTON_ALIGNMENT.CENTER}
          noTracking
          className={classNameBuilder(className && styles[className])}
          onClick={handleOnClick}
          symbol={copied ? 'check' : undefined}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
}
