import { Button, Icon } from '@geberit/gdds';

// types
import type { BasketBoxProps } from '../types'

// styles
import styles from './dlc-basket-box.module.scss';

// utils
import { useTranslationFunction } from 'utils/hooks/use-translations';

export default function BasketBox(props: Readonly<BasketBoxProps>) {
  const {
    orderCounter,
    downloadCounter,
    setShowLightbox,
    showLightbox,
  } = props;
  const translate = useTranslationFunction();

  return (
    <div className={styles.basketBox}>
      <div className={styles.basketHeader}>{translate('web20_downloadcenter_cart')}</div>
      <div className={styles.basketButtons}>
        <div className={styles.basketIcon}>
          <Icon symbol="Download" />
        </div>
        <div className={styles.basketDescription}>
          <Button
            stylingType="flatHighlight"
            stretched
            onClick={() => {
              if (downloadCounter > 0) {
                setShowLightbox(!showLightbox ? 'download' : undefined);
              }
            }}
            disabled={downloadCounter <= 0}
          >
            {translate('web20_downloadcenter_cartdownload_as_zip')}
          </Button>
        </div>
        <div className={styles.counterContainer}>
          <div className={styles.cartCounter}>{downloadCounter}</div>
        </div>
      </div>
      <div className={styles.basketButtons}>
        <div className={styles.basketIcon}>
          <Icon symbol="Onlineshop" />
        </div>
        <div className={styles.basketDescription}>
          <Button
            stylingType="flatHighlight"
            stretched
            disabled={orderCounter <= 0}
            onClick={() => {
              if (orderCounter > 0) {
                setShowLightbox(!showLightbox ? 'order' : undefined);
              }
            }}
          >
            {translate('web20_downloadcenter_order')}
          </Button>
        </div>
        <div className={styles.counterContainer}>
          <div className={styles.cartCounter}>{orderCounter}</div>
        </div>
      </div>
    </div>
  );
}
