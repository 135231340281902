import styled from 'styled-components';
import { Button } from '@geberit/gdds';

export const FilterCloseButton = styled(Button)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  z-index: 99;

  span {
    display: flex;
    align-items: center;

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
`;
