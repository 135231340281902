import React from 'react';
import styled, { keyframes } from 'styled-components';

interface StyledSpanProps {
  pending?: boolean;
  active?: boolean;
}

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `;
}

const StyledSpan = styled.span<StyledSpanProps>`
  & .point {
    animation: ${blinkingEffect} ${({ pending }) => (pending ? '1s linear infinite' : 'none')};
  }

  & svg path,
  & svg circle {
    stroke: ${({ active, theme }) => (active ? theme.colors.primaryLight : theme.colors.black)};
  }

  & .point {
    fill: ${({ active, theme }) => (active ? theme.colors.primaryLight : theme.colors.black)};
  }
`;

export function LocationIcon(props: React.ButtonHTMLAttributes<HTMLSpanElement> & StyledSpanProps) {
  return (
    <StyledSpan {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8 0V2" stroke="black" strokeWidth="1.25" />
        <path d="M8 14V16" stroke="black" strokeWidth="1.25" />
        <path d="M0 8H2" stroke="black" strokeWidth="1.25" />
        <path d="M14 8H16" stroke="black" strokeWidth="1.25" />
        <circle cx="8.12012" fill="white" cy="8.11719" r="6.25" stroke="black" />
        <circle className="point" cx="8" cy="8" r="2.5" fill="black" stroke="black" />
      </svg>
    </StyledSpan>
  );
}
