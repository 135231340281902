import { useState, useEffect } from 'react';

// types
import type { IWithDistance } from '../utils/pins-manager';
import type { IEntryWithKey } from '../providers/filter-provider';

// utils
import { translateCenter } from '../utils/translate-pin';

export function useSyncEntries(
  map: google.maps.Map | null,
  entries: IWithDistance<IEntryWithKey>[],
) {
  const [markers, setMarkers] = useState<IWithDistance<IEntryWithKey>[]>([]);

  useEffect(() => {
    if (map === null) {
      return;
    }

    const handler = () => {
      setMarkers(entries.filter(whichIsInBound(map)));
    };
    const identifier = map.addListener('idle', handler);

    return () => {
      if (typeof google === 'undefined') {
        return;
      }

      google.maps?.event?.removeListener(identifier);
    };
  }, [map, entries]);

  return markers;
}

function whichIsInBound(map: google.maps.Map): (value: IWithDistance<IEntryWithKey>) => boolean {
  const bounds = boundsWithPadding(map, { left: 25, top: 5, right: -25, bottom: 35 });
  return (entry) => Boolean(bounds?.contains(entry));
}

function boundsWithPadding(
  map: google.maps.Map,
  { left, top, right, bottom }: { left: number; top: number; right: number; bottom: number },
) {
  const bounds = map?.getBounds();
  const northEast = bounds?.getNorthEast();
  const southWest = bounds?.getSouthWest();
  if (!northEast || !southWest) {
    return bounds;
  }

  return new google.maps.LatLngBounds(
    translateCenter(map, southWest, left, bottom),
    translateCenter(map, northEast, right, top),
  );
}
