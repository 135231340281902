import styled from 'styled-components';

import { NoteWrapper } from '../detail-page/note/note.styles';

export const CSSVariables = styled.div<{ portalGap: string }>`
  --map-height: 18.75rem;
  --portal-gap: ${({ portalGap }) => portalGap};
  --list-view-width: calc(100% + 2rem);
  --list-view-margin-left: -1rem;
  --transition-delay: 0s;

  @media ${({ theme }) => theme.device.medium} {
    --map-height: 28.25rem;
    --list-view-width: calc(100% + 3rem);
    --list-view-margin-left: -1.5rem;
  }

  @media ${({ theme }) => theme.device.large} {
    --map-height: 38.25rem;
  }
`;

export const StyledMapWrapper = styled.div`
  position: relative;

  @media ${({ theme }) => theme.device.large} {
    display: flex;
    column-gap: 1rem;
    flex-wrap: wrap;
  }

  ${NoteWrapper} {
    margin-bottom: 2.75rem;

    @media ${({ theme }) => theme.device.large} {
      flex-basis: 40.125rem;
      margin-bottom: 3.75rem;
      margin-left: auto;
      margin-right: auto;
    }

    @media ${({ theme }) => theme.device.xlarge} {
      flex-basis: 50.875rem;
    }

    @media (min-width: 90rem) {
      flex-basis: 57.75rem;
    }
  }

  .notification {
    top: 0rem;

    @media ${({ theme }) => theme.device.medium} {
      top: 1.5rem;
      padding: 0 1.5rem;
    }

    @media ${({ theme }) => theme.device.large} {
      padding: 0 1rem;
      right: 0;
      top: unset;
      bottom: 1.5rem;
    }
  }
`;

export const StyledMapPortal = styled.div`
  position: fixed;
  top: 3.75rem;
  left: 0;
  right: 0;
  height: var(--map-height);
  min-height: var(--portal-gap);
  transition: height 0.3s var(--transition-delay) linear;
  z-index: 3;
  overflow: hidden;

  .fixed & {
    position: relative;
  }

  @media ${({ theme }) => theme.device.large} {
    width: calc(calc(100% - 1rem) / 1.5);
    position: relative;
    top: unset;
    z-index: unset;
    max-height: unset;
  }
`;

export const StyledMap = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: var(--map-height);
  transition: height 0.3s var(--transition-delay) linear;
  ${({ active }) => (!active ? `-webkit-transform: translate3d(0,0,0);` : '')}

  @media ${({ theme }) => theme.device.large} {
    position: static;
  }

  .cluster {
    :hover {
      background: url('/images/locator/marker-cluster-hover.svg') no-repeat;

      &.large {
        background: url('/images/locator/large-marker-cluster-hover.svg') no-repeat;
      }

      > img {
        display: none;
      }
    }
  }
`;

export const TransparentLayer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  &.active {
    pointer-events: all;
    cursor: pointer;
  }

  @media ${({ theme }) => theme.device.large} {
    display: none;
  }
`;

export const StyledMapPlaceholder = styled.div`
  position: sticky;
  transition: height 0.3s var(--transition-delay) linear;
  top: 3.75rem;
  height: var(--map-height);
  width: 100%;

  @media ${({ theme }) => theme.device.large} {
    display: none;
  }
`;

export const MapSearchBox = styled.div<{ sticky: boolean }>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  top: ${({ sticky }) => (sticky ? '5.75rem' : 'unset')};
  z-index: 3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: var(--list-view-width);
  margin-left: var(--list-view-margin-left);
  padding: 1.25rem 1rem;
  background-color: white;

  .fixed & {
    position: static;
  }

  > div {
    width: 100%;
  }

  & .filter-button {
    height: 2.75rem;
    min-width: 2.75rem;
  }

  @media ${({ theme }) => theme.device.medium} {
    padding: 1.25rem 1.5rem;

    & > div {
      width: 20rem;
    }
  }

  @media ${({ theme }) => theme.device.large} {
    position: absolute;
    top: 0;
    width: 20rem;
    left: 1.25rem;
    background-color: initial;

    && > div {
      flex-grow: 1;
    }
  }
`;
