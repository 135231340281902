import { useMemo } from 'react';
import VideoPlayButton from '../VideoPlayButton';

type YoutubeThumbnailProps = {
  id: string;
  onClick: () => void;
};

function YoutubeThumbnail({ id, onClick }: Readonly<YoutubeThumbnailProps>) {
  const thumbnailStyle = useMemo(
    () => ({
      backgroundImage: `url(https://img.youtube.com/vi/${id}/0.jpg)`,
    }),
    [id],
  );

  return (
    <div
      style={thumbnailStyle}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
      className="c-youtube-video-link"
    >
      <VideoPlayButton />
    </div>
  );
}

export default YoutubeThumbnail;
