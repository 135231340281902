import { useContext, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { useFormikContext } from 'formik';

// components
import { FormContext } from '../../form-context';
import { InputFieldView } from './input-field-view';
import { InputFieldViewGdds } from './input-field-view-gdds';

// utils
import { useGdds } from 'utils/hooks/use-gdds';

export type InputFieldField = {
  type: string;
  name: string;
  placeholder: string;
  label: string;
  comment: string;
  maxFileCount?: number;
  required?: boolean;
  readonly?: boolean;
  rules: Rule[];
};

interface InputFieldProps {
  field: InputFieldField;
  customType: string;
  hasValidPrecondition?: string;
}

export default function InputField({
  field,
  hasValidPrecondition,
  customType,
}: Readonly<InputFieldProps>) {
  const { type, name, rules } = field;
  const { handleBlur, handleChange, setFieldValue, values, errors, touched } =
    useFormikContext<Record<string, string | string[]>>();
  const { addFile, deleteFile } = useContext(FormContext);
  const isGdds = useGdds();
  const isFileUpload = type === 'file';

  useEffect(() => {
    // if this field has a precondition and this is not enabled, don't throw an error
    if (errors[name] && hasValidPrecondition) {
      delete errors[name];
    }

    // reset value if it is hidden
    if (values[name] && hasValidPrecondition) {
      setFieldValue(name, '');
    }
  }, [errors, hasValidPrecondition, name, setFieldValue, values]);

  const getClassNames = () => {
    const classes = ['form-item'];

    if (
      (errors[name] && touched[name] && !isEmpty(rules) && !hasValidPrecondition) ||
      (errors[name] && touched[name])
    ) {
      classes.push('form-item--error');
    }

    if (touched[name] && !isFileUpload) {
      classes.push('form-item--filled');
    }

    // isEmpty returns true if a number is written
    if (!isFileUpload && touched[name] && !values[name]) {
      classes.push('form-item--empty');
    }

    if (type === 'hidden') {
      classes.push('hide');
    }

    return classes.join(' ');
  };

  const handleOnChange = (
    e?: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    maxFileCount?: number,
  ) => {
    if (e?.currentTarget.type === 'file') {
      addFile(e, maxFileCount ?? 0);
      setFieldValue(name, values[name].concat(e.currentTarget.value));
    } else {
      // trigger touched event to trigger span transition
      handleBlur(e);

      handleChange(e);
    }
  };

  const handleOnDelete = (name, errorMessage, index) => {
    setFieldValue(
      name,
      (values[name] as string[]).filter((_, i) => i !== index),
    );
    deleteFile(name, errorMessage, index);
  };

  if (isGdds && !isFileUpload) {
    return <InputFieldViewGdds field={field} handleOnChange={handleOnChange} />;
  }

  return (
    <InputFieldView
      field={field}
      className={getClassNames()}
      deleteFile={handleOnDelete}
      handleOnChange={handleOnChange}
      customType={customType}
    />
  );
}
