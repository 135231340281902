import styled, { css } from 'styled-components';

export const StyledArticle = styled.article<{ isActive: boolean; isHovered: boolean }>`
  ${({ theme, isActive, isHovered }) => {
    return css`
      padding-top: 1.25rem;
      display: grid;
      grid-template-columns: 3fr 1.25fr;
      grid-template-areas:
        'address distance'
        'buttons buttons'
        'divider divider';

      > hr {
        margin-top: 0.75rem;
        grid-area: divider;
      }

      @media ${theme.device.medium} {
        grid-template-columns: 4.5fr 2fr 1.125fr;
        grid-template-areas:
          'address buttons distance'
          'divider divider divider';
      }

      @media ${theme.device.large} {
        grid-template-columns: 3fr 1.25fr;
        grid-template-areas:
          'address distance'
          'buttons buttons'
          'divider divider';
        ${(isHovered || isActive) && `background-color: ${theme.colors.grey03}`};
        ${isActive
          ? `border: 2px solid ${theme.colors.primary}; padding: 1.125rem 0.875rem 0 0.875rem;`
          : 'padding: 1.25rem 1rem 0 1rem;'};

        > hr {
          margin: ${isActive ? '0.625rem' : '0.75rem'} -1rem 0 -1.25rem;
          width: calc(100% + 2.25rem);
        }
      }
    `;
  }}
`;

export const LeftCol = styled.div`
  grid-area: address;

  > p {
    margin: 0;

    :first-of-type {
      margin-top: 0.5rem;
    }
  }
`;

export const RightCol = styled.div`
  grid-area: distance;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  column-gap: 1rem;
  margin-left: auto;

  > img {
    height: 2.25rem;
    margin-bottom: 1rem;
  }

  .distance-duration {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > p {
      margin: 0;
      white-space: nowrap;
    }
  }
`;

export const StyledButtonsWrapper = styled.div`
  grid-area: buttons;
  display: flex;
  margin-top: 0.5rem;

  > button {
    transform: translate(-0.5rem);
  }

  @media ${({ theme }) => theme.device.medium} {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  @media ${({ theme }) => theme.device.large} {
    flex-direction: initial;
    justify-content: initial;
    align-items: initial;
  }
`;
