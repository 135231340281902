import key from 'weak-key';
import json2mq from 'json2mq';
import { Col, Row, Divider, Carousel } from '@geberit/gdds';

// styles
import styles from './contact-tiles.module.scss';

// types
import type { ContactTilesProps, RelatedMaterialsDisplayMode } from '../types/press-portal.types';
import type { ContactPeople, Presscontact } from '../types/mynewsdesk.types';

// components
import { ContactTile } from './contact-tile';
import Title from '../../title/title';

// utils
import { useMediaQuery } from '../hooks/use-media-query';
import { styleConfig } from 'config';
import { classNameBuilder } from 'utils/classNameBuilder';
import { isEmpty } from 'utils/is-empty';
import { useTranslationByKey } from 'utils/hooks/use-translations';

export default function ContactTiles({
  contacts,
  withDivider = true,
  isGdds,
}: Readonly<ContactTilesProps>) {
  const isMobile = useMediaQuery(
    json2mq({
      maxWidth: styleConfig.responsive.mobileMax,
    }),
  );
  const isTablet = useMediaQuery(
    json2mq({
      minWidth: styleConfig.responsive.tabletMin,
      maxWidth: styleConfig.responsive.tabletGddsMax,
    }),
  );
  const contactUsTranslation = useTranslationByKey('press_contact_us');

  if (isEmpty(contacts)) return null;

  const relatedMaterialsDisplayMode: RelatedMaterialsDisplayMode =
    contacts.length > 2 ? 'three_per_row' : 'two_per_row';

  function renderContacts() {
    const contactTiles = contacts.map((contact: ContactPeople | Presscontact) => {
      return <ContactTile key={key(contact)} isMobile={isMobile} contact={contact} />;
    });

    if (isMobile && contactTiles.length > 1) {
      return (
        <Carousel supportMouse hideButtons hideButtonsTablet>
          {contactTiles}
        </Carousel>
      );
    }

    if (isTablet && contactTiles.length > 2) {
      return (
        <Carousel supportMouse hideButtons hideButtonsTablet>
          {contactTiles}
        </Carousel>
      );
    }
    return (
      <div
        className={classNameBuilder(
          styles.contactsWrapper,
          relatedMaterialsDisplayMode === 'three_per_row' && styles.threeTilesPerRow,
        )}
      >
        {contactTiles}
      </div>
    );
  }

  return (
    <>
      {withDivider && <Divider />}
      <Title title={contactUsTranslation} />
      <Row className={styles.contacts}>
        {relatedMaterialsDisplayMode === 'two_per_row' && <Col size={[0, 0, 2]}></Col>}
        <Col size={[4, 8, relatedMaterialsDisplayMode === 'two_per_row' ? 8 : 12]}>
          {renderContacts()}
        </Col>
        {relatedMaterialsDisplayMode === 'two_per_row' && <Col size={[0, 0, 2]}></Col>}
      </Row>
    </>
  );
}
