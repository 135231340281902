export function translateCenter(
  map: google.maps.Map,
  latlng: google.maps.LatLngLiteral | google.maps.LatLng,
  offsetX: number,
  offsetY: number,
) {
  if (!map) {
    return undefined;
  }

  const point1 = map.getProjection()?.fromLatLngToPoint(latlng);

  if (!point1) {
    return undefined;
  }

  const point2 = new google.maps.Point(
    offsetX / Math.pow(2, map.getZoom() || 0),
    offsetY / Math.pow(2, map.getZoom() || 0),
  );

  return map
    .getProjection()
    ?.fromPointToLatLng(new google.maps.Point(point1.x - point2.x, point1.y + point2.y));
}
