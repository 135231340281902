import { useContext } from 'react';
import { useFormikContext } from 'formik';

// components
import { BUTTON_BACKGROUND, BUTTON_COLORS, BUTTON_TYPES, Button } from 'components/Button/Button';
import { Translation } from 'components/Translation/Translation';

// utils
import { FormContext } from '../../form-context';
import { isEmpty } from 'utils/is-empty';

export interface ButtonFieldProps {
  buttons: Field['buttons'];
  disabled?: boolean;
}

export function ButtonField({ buttons, disabled }: Readonly<ButtonFieldProps>) {
  const { handleReset } = useFormikContext();
  const { formType, uploadProgress, hasFileUpload, hasOxomiButton, oxomiButtonClick } =
    useContext(FormContext);
  if (isEmpty(buttons)) {
    return null;
  }

  const uploadIconAnimation = uploadProgress > 0 ? 'upload-icon-animation' : '';

  return (
    <>
      {hasOxomiButton && (
        <div className="form-item--button-abort">
          <Button
            type={BUTTON_TYPES.PLAIN}
            color={BUTTON_COLORS.PRIMARY}
            onClick={oxomiButtonClick}
            symbol="arrow-link-light-left"
          >
            <Translation id="web20_oxomi_abort_order" />
          </Button>
        </div>
      )}
      {buttons.map((button) => {
        const isSubmitButton = button.type === 'submit';

        return (
          <Button
            key={button.id}
            className={`form-item--button ${uploadIconAnimation}`}
            type={BUTTON_TYPES.DEFAULT}
            symbol={
              (formType &&
                ((hasFileUpload && !isSubmitButton) || !hasFileUpload) &&
                'arrow-link-light-right') ||
              undefined
            }
            color={BUTTON_COLORS.WHITE}
            background={isSubmitButton ? BUTTON_BACKGROUND.PRIMARY : BUTTON_BACKGROUND.GREY_BLUE}
            onClick={!isSubmitButton ? handleReset : undefined}
            buttonType={isSubmitButton ? button.type : 'button'}
            disabled={disabled}
          >
            {hasFileUpload && isSubmitButton ? (
              <>
                <div className="icon-upload__wrapper">
                  <span className="icon-upload__icon" />
                </div>
                <span className="progress-bar" style={{ width: `${uploadProgress}%` }}></span>
                <span className="upload-button--text">
                  {uploadIconAnimation && isSubmitButton ? (
                    <Translation id="web20_forms_uploading_files" />
                  ) : (
                    button.label
                  )}
                </span>
              </>
            ) : (
              button.label
            )}
          </Button>
        );
      })}
    </>
  );
}
